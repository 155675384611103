import React from 'react';
import { Modal } from 'react-bootstrap';
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
// import Modal from 'react-bootstrap/Modal';

export default function MyLightBox({open,setOpen,srcList}) {
    if(srcList.length===0){
        return (
            <div
            className="modal show"
            style={{ display: 'block', position: 'initial' }}
            >
                <Modal show={open} onHide={()=>setOpen(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: "center"}}>No Documents Found</Modal.Body>
                </Modal>
            </div>
        );
        }
    return (
        <>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={srcList}
                plugins={[Fullscreen, Video, Zoom, Slideshow, Thumbnails]}
            />
        </>
    );
}