
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { BiBlock } from 'react-icons/bi'
import { CgUnblock } from 'react-icons/cg'
import { BlockSubAdmin, GetAllSubAdmins, UnBlockSubAdmin } from '../../../helpers/BaseFn';
import { AddIcon, BackIcon, EditIcon, ItemsPerPagePaginate } from '../../../helpers/Icons';
import TableSkeleton from '../../../components/tables/TableSkeleton';
import Header from '../../../components/Header';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../../redux/Slices/LoadingSlice';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PaginateButtons from './PaginateButtons';
import EditSubAdminModal from './EditSubAdminModal';
import SubAdminDetails from './SubAdminDetails';
import AddSubAdminModal from './AddSubAdminModal';
import SubAdminTbl from './SubAdminTbl';
const SubAdminList = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [subAdminList, setSubAdminList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const dispatch = useDispatch();
   const [activeSubAdmins, setActiveAgents] = useState([]);
  const [blockedSubAdmins, setBlockedAgents] = useState([]);
  const [showActiveAgents, setShowActiveAgents] = useState(true);
  const [activePage, setActivePage] = useState(1);
   const [blockedPage, setBlockedPage]  = useState(1);

   const [EditModal,setEditModal]       = useState(false);
   const [detailsModal,setdetailsModal] = useState(false);
   const [DetailsData,setDetailsData]   = useState({});
   const [addModal,setAddModal] = useState(false)


   const itemsPerPage = ItemsPerPagePaginate; 
   const LoadPage = async () => {
      setLoading(true);
      dispatch(setReduxLoading(true));
      let result = await GetAllSubAdmins();
      setSubAdminList(result);
      setLoading(false);
      dispatch(setReduxLoading(false));
   };

   const HandleBlock = async (id) => {
      const result = await BlockSubAdmin(id);
      if (result?.status) {
         toast.success("Blocked Successfully");
         LoadPage();
      } else {
         toast.error(result?.data?.message);
      }
   };

   const HandleUnBlock = async (id) => {
      const result = await UnBlockSubAdmin(id);
      if (result?.status) {
         toast.success("UnBlocked Successfully");
         LoadPage();
      } else {
         toast.error(result?.data?.msg);
      }
   };

   const activeIndexOfLastItem = activePage * itemsPerPage;
   const activeIndexOfFirstItem = activeIndexOfLastItem - itemsPerPage;
   const activeCurrentItems = activeSubAdmins.slice(activeIndexOfFirstItem, activeIndexOfLastItem);

   const blockedIndexOfLastItem = blockedPage * itemsPerPage;
   const blockedIndexOfFirstItem = blockedIndexOfLastItem - itemsPerPage;
   const blockedCurrentItems = blockedSubAdmins.slice(blockedIndexOfFirstItem, blockedIndexOfLastItem);

   const handleActivePageChange = (newPage) => {
      setActivePage(newPage);
   };

   const handleBlockedPageChange = (newPage) => {
      setBlockedPage(newPage);
   };

   useEffect(() => {
      LoadPage();
   }, []);

   useEffect(() => {
    // Filter agents based on their block status
    const activeSubadminsList = [];//subAdminList.filter((SubAdmins) => !SubAdmins.isBlocked);
    const blockedSubAdminList = [];//subAdminList.filter((SubAdmins) => SubAdmins.isBlocked);


for (let index = 0; index < subAdminList.length; index++) {
   const subAdmin = subAdminList[index];
   const obj = {
      name: subAdmin.first_name + ' ' +subAdmin.last_name,
      ...subAdmin
   }
   if(subAdmin?.isBlocked){
      blockedSubAdminList.push(obj);
   }else{
      activeSubadminsList.push(obj);
   }
}


    setActiveAgents(activeSubadminsList);
    setBlockedAgents(blockedSubAdminList);
  }, [subAdminList]);
   
    const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   // const currentItems = subAdminList.slice(indexOfFirstItem, indexOfLastItem);
const styles= {
   title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
   }
}
   return (
      <>
         <Header />
         <main>
            <section className="all-scripts py-4">
               <div className="top-heading bg-light-gray py-4">
                  <div className="container d-flex justify-content-start align-items-center">
                     <h4 className='text-gray-heading text-capitalize' style={styles.title}>
                        <span>
                        <button className='btn text-primary' type="button"
                           onClick={() => navigate(-1)}  
                        ><BackIcon /></button>
                        SubAdmins Detail
                        </span>
                        <span>
                           <button className='btn text-primary' type="button"
                              onClick={() =>  setAddModal(true) }  
                           >  <span style={{width:'10px',fontSize:'20px'}}> + </span>  Add New SubAdmin</button>
                        </span>
                     </h4>
                  </div>
               </div>
                    
               <div className="container">

               <Tabs className={'mt-5'}>
							<TabList>
								<Tab>Active SubAdmins</Tab>
								<Tab>Blocked SubAdmins</Tab> 
							</TabList>
    <TabPanel>
      
    <SubAdminTbl
      data={activeSubAdmins}
      setdetailsModal={setdetailsModal}
      setEditModal={setEditModal}
      setDetailsData={setDetailsData}
      HandleUnBlock={HandleUnBlock}
      HandleBlock={HandleBlock}
    />
</TabPanel>

  <TabPanel>
  <SubAdminTbl
      data={blockedSubAdmins}
      setdetailsModal={setdetailsModal}
      setEditModal={setEditModal}
      setDetailsData={setDetailsData}
      HandleUnBlock={HandleUnBlock}
      HandleBlock={HandleBlock}
    />
   
</TabPanel>

  </Tabs>


                  <AddSubAdminModal
                     show={addModal}
                     handleClose={() => setAddModal(false)}
                     refreashList={LoadPage}
                  />

               <EditSubAdminModal
                     show={EditModal}
                     handleClose={() => setEditModal(false)}
                     subAdminId={DetailsData?._id}
                     refreashList={LoadPage}
                  />
                  

                  <SubAdminDetails 
                     show={detailsModal}
                     handleClose={() => setdetailsModal(false)}
                     subAdminData={DetailsData}
                  />

               </div>
            </section>
         </main>
      </>
   );
};

export default SubAdminList;
