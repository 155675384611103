import React from 'react';

import DataGrid, { Scrolling, Pager, Paging, Column,SearchPanel } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ScriptDetailsDrop from '../dropdowns/ScriptDetailsDrop';


const DashboardTbl = ({data,handleDelete,setOpenEditModal,handleShow,is_deactivated,handleActivate}) => {
    const RenderAction = (e) => {
        return (
            <ScriptDetailsDrop
                              handleDelete={handleDelete}
                              item={e.data}
                              setOpenEditModal={setOpenEditModal}
                              handleShow={handleShow}
                              is_deactivated={is_deactivated}
                              handleActivate={handleActivate}
                            />
        );
    }

    const renderTitle = (e)=>{
        return  (

            <Link
                              to={"/script-details/" + e?.data?._id}
                              className="btn link text-primary text-truncate-720"
                              title={e?.value}
                            >
                              {e?.value}
                            </Link>

        )
    }
    const renderDate = (e)=>{
        return (moment(e?.value).format('DD MMM YYYY'))
    }
    const allowedPageSizes = [5, 10, 'all'];
  return (
    <>
    
    
    <DataGrid
          id='gridContainer'
          dataSource={data}
          keyExpr="_id"
          showBorders={false}
        >
          {/* <Column dataField="_id" caption="ID" /> */}
          <Column dataField="script_title" caption="Script Title" cellRender={renderTitle} />
          <Column dataField="createdAt" caption="Created Date" cellRender={renderDate} />
          <Column dataField="_id" caption="Action" cellRender={RenderAction} width={250} />

          <Scrolling rowRenderingMode='virtual'/>
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={true}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
             />
          <Paging defaultPageSize={10} />
          <SearchPanel  visible={true}/>
    </DataGrid>
    </>
  )
}

export default DashboardTbl