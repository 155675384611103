import * as React from "react";
import { AddIcon, EditIcon, InfoIcon } from '../../helpers/Icons';
import { BASE_IMG } from '../../helpers/BaseFn';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import MyLightBox from "./MyLightBox";

function VerbiageAccordian({
  index,
  item,
  setLeadScript,
  getVerbiageDetails,
  handleAddScript,
  leadScript,
  images,
  videos,
  setLightboxOpen,
  setcombinedMedia
   // Import your slides data here
}) {


  const [lightboxIndex, setLightboxIndex] = React.useState(0);

  // Function to open the lightbox with a specific index
  const openLightbox = (index) => {
    // console.log("lightboxOpen:", lightboxOpen);
    
    setLightboxIndex(index);
    setLightboxOpen(true);
    
    const combinedMedia = [
      ...images.map((image) => ({
        url: BASE_IMG + image,
        src: BASE_IMG + image,
        type: 'image',
        altTag: 'Image',
        width: 1280,
        height: 720,
      })),
      ...videos.map((video) => ({
        url: BASE_IMG + video,
        src: BASE_IMG + video,
        ...{
            type: "video",
            title: "Big Buck Bunny",
            description:
              "The Peach Open Movie Project\n\nBlender Institute, Netherlands",
            width: 1280,
            height: 720,
            // poster:
            //   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
            sources: [
              {
                src: BASE_IMG + video,
                type: "video/mp4",
              },
            ],
          },
      })),
    ];
    console.log({combinedMedia});
    setcombinedMedia(combinedMedia);
  };



  // Create an array of media items (images and videos)
  const HandleIconClick = ()=>{
    const combinedMedia = [
      ...images.map((image) => ({
        url: BASE_IMG + image,
        src: BASE_IMG + image,
        type: 'photo',
        altTag: 'Image',
      })),
      ...videos.map((video) => ({
        url: BASE_IMG + video,
        src: BASE_IMG + video,
        type: 'video',
        title: 'Video',
      })),
    ];
  }

  // console.log("combinedMedia:", combinedMedia);
  return (
    <div>
      <div
        className={'accordion-item'}
        key={index}
        style={{ boxShadow: '0px 2px 0px 0px #ccc' }}
      >
        <h2 className="accordion-header" id={item?._id}>
          <div
            className={`d-flex gap-2 justify-content-between align-items-center collaped`}
            onClick={() => {
              leadScript === item?._id
                ? setLeadScript('item?._id')
                : setLeadScript(item?._id);
            }}
          >
            {leadScript === item?._id ? (
              <div className="accordion-open">-</div>
            ) : (
              <div className="accordion-close">+</div>
            )}
            {
              <button
                className={`accordion-button btn  d-flex gap-2 justify-content-between align-items-center `}
                type="button"
              >
                <b> {item?.verbiage_title}</b>
              </button>
            }
            <div className="d-flex gap-1">
              <button
                className="btn text-primary pe-0"
                type="button"
                onClick={() => getVerbiageDetails(item?._id)}
                style={{ width: '40px' }}
              >
                <span
                  style={{ width: '40px', color: 'rgb(38, 138, 255)' }}
                  title="Edit Verbiage"
                >
                  <EditIcon color="#d3dae4" />
                </span>
              </button>
              <button
                className="btn text-primary  px-0"
                type="button"
                onClick={() => handleAddScript(item?._id, '', 0)}
                style={{ width: '28px' }}
                title="Add verbiage"
              >
                <span style={{ width: '28px', color: 'rgb(38, 138, 255)' }}>
                  <AddIcon />
                </span>
              </button>
              <button
                className="btn text-primary  px-0"
                type="button"
                onClick={openLightbox}
                style={{ width: '35px' }}
              >
                <span
                  style={{ width: '35px', color: 'rgb(38, 138, 255)' }}
                  title="View Info Files"
                >
                  <InfoIcon color="#d3dae4" />
                </span>
              </button>
            </div>
          </div>
        </h2>
        <div
          id={`collapse_One_` + item?._id}
          className={`accordion-collapse collapse ${
            item?._id === leadScript || item?.btn_id === leadScript
              ? 'show'
              : 'hide'
          }`}
          aria-labelledby={item?._id}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div
              dangerouslySetInnerHTML={{ __html: item?.verbiage_description }}
            />
            <div className="view-button-group mt-4 mb-2">
              {item.btn_arr.map((it, i) => (
                <div className="form-group" key={i}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id={"no-objection-btn_" + it?._id}
                    checked={parseInt(it?.is_created)}
                    autoComplete="off"
                    readOnly
                    onClick={() =>
                      handleAddScript(item?._id, it?._id, parseInt(it?.is_created))
                    }
                  />
                  <>
                    {parseInt(it?.status) === 0 ? (
                      <label
                        className="btn  btn-outline-warning px-3 py-2 "
                        htmlFor={"no-objection-btn_" + it?._id}
                      >
                        {it?.title}
                      </label>
                    ) : (
                      <label
                        className="btn  btn-outline-primary px-3 py-2 "
                        htmlFor={"no-objection-btn_" + it?._id}
                      >
                        {it?.title}
                      </label>
                    )}
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* {lightboxOpen && (
        <Lightbox
    
          data={combinedMedia} // Pass the combinedMedia array
          startIndex={lightboxIndex} // Set the initial index
          onCloseCallback={() => setLightboxOpen(false)} // Callback to close the lightbox
          plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
          // Add other configurations or plugins as needed
        />
      )} */}


    </div>
  );
}

export default VerbiageAccordian;
