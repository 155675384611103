import React, { useEffect, useRef, useState } from 'react';

import { CallGETAPI, CallPOSTAPI, CallPOSTFORMAPI } from '../../helpers/API';
import { toast } from 'react-toastify';
import ScriptSelector from '../../components/ScriptForm/ScriptSelector';
import { BiImageAdd } from 'react-icons/bi'
import { Form } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { AngleRight, BackIcon } from '../../helpers/Icons';
import { GetScriptDetail, getVerbiagebyId } from '../../helpers/BaseFn';
// import CKEditorWrapper from './ckeditor';
import MyCkeditor from '../../components/ckeditor/MyCkeditor';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router';
import { AiFillDelete } from 'react-icons/ai';
import RenderFileInput from './RenderFileInput';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../redux/Slices/LoadingSlice';


function CreateVerbiage() {
  const FormRef         = useRef();
  const navigate        = useNavigate();
  const params          = useParams();
  const id              = params?.script_id || '';
  const [searchParams]  = useSearchParams();
  const dispatch = useDispatch();


  const verbiage_id = searchParams.get('verbiage_id') || ''; //router?.query?.verbiage_id;
  const btnId = searchParams.get('btnId') || ''; //router?.query?.btnId;

const verbiate_title_ref = useRef();
  const [buttonRows, setButtonRows] = useState([{ title: '' }]);
  const [verbiageList, setVerbiageList] = useState([]);
  const [selectedVerbiage, setSelectedVerbiage] = useState(null);
  const [selectedBtn, setSelectedBtn] = useState(btnId);
  const [validated, setValidated] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [file, setFile] = useState([])
  const FileRef = useRef(null)
  const [verbiageDescriptionError, setVerbiageDescriptionError] = useState('');
  const descriptionRef = useRef(null);
  function isValidFileType(file, allowedTypes) {
    const fileType = file.type.split('/')[1];
    return allowedTypes.includes(fileType);
  }
  
  const allowedImageTypes = ['jpeg', 'png'];
  const allowedVideoTypes = ['webm', 'mp4'];
  const handleUpload = e => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => isValidFileType(file, [...allowedImageTypes, ...allowedVideoTypes]));
    const invalidFiles = files.filter(file => !isValidFileType(file, [...allowedImageTypes, ...allowedVideoTypes]));
    // const filename = validFiles.map(file => file.name).join(", ");
    if (invalidFiles.length > 0) {
      // toast.error("Invalid file types: " + invalidFiles.map(file => file.name).join(", "));
      toast.error("Invalid file type");
      return '';
    }
    // console.log({file,fileLen: file.length})
    const fileLen = files?.length +file?.length; 
    if(fileLen  > 10){
      toast.error("More then 10 files not allowed");
      return '';
    }

    const validFilesWithNames = validFiles.map(file => ({
      file: file,
      name: 'filename' // Set the desired filename here
    }));
    
    setFile(prev => [...prev, ...validFiles]);
  }

  const removeThisImage = (i) => {
    let newValues = [...file];
    newValues.splice(i, 1);
    setFile(newValues);
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);



 


  const [loading, setLoading] = useState({
    loading_status: false,
    msg: '',
  });
  const handleAddButton = () => {
    let buttonAllRows = [...buttonRows]
    let newRow = { title: '' }
    buttonAllRows.push(newRow);
    setButtonRows(buttonAllRows);
  }

  const handleRemoveButton = (index) => {
    // get all button rows
    let buttonAllRows = [...buttonRows];
    // Remove the object at the found index
    buttonAllRows.splice(index, 1);
    // set all button rows
    setButtonRows(buttonAllRows);
  }
  const [ScriptDetails, setScriptDetails] = useState({});
  const fetch = async () => {
    if (!id) return "";
    dispatch(setReduxLoading(true));
    const res = await CallGETAPI('script/get_verbiage_data?script_id=' + id);
    const result = res?.data?.data || [];
    let optioan_val = result.map((item) => ({ ...item, label: item.verbiage_title, }))

    let scriptData = await GetScriptDetail(id);
    const getSelected = optioan_val.find((item) => (item?._id === verbiage_id))

    if (getSelected) {
      setSelectedVerbiage(getSelected);
    }
    setScriptDetails(scriptData)
    setVerbiageList(optioan_val)
    dispatch(setReduxLoading(false));
  }

  const [oLoader,setoLoader] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading({ msg: "", loading_status: true });
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setLoading({ msg: "", loading_status: false });
      setValidated(true);
      verbiate_title_ref.current.focus();
      e.stopPropagation();
    } else {

      let btnArr = [...buttonRows];
       let FilterBtn = btnArr.filter((item) =>  item.title.trim() !== "");
      // let  checked  = e.target.set_up_next.value;
      if (checkbox) {
        FilterBtn.push({ title: 'Continue', no_objection: 1 });
      }

      if (verbiageList.length > 0) {
        if (!selectedBtn) {
          setLoading({ msg: "Please Select Any Button", loading_status: false });
          toast.error("Please Select Any Button");
          return;
        }
      }
      if (!data) {
        setVerbiageDescriptionError('  Please enter a verbiage description.');
        setLoading({ msg: '', loading_status: false });
        descriptionRef.current = Math.random(10,1000);
        console.log("hello")
        return '';
      } else {
        setVerbiageDescriptionError(''); 
      }
     
    setoLoader(true);
      let send_data = {
        verbiage_title: e.target.verbiage_title.value,
        script_id: id,
        verbiage_description: data,
        btn_arr: FilterBtn,
        media: {
          images: file.filter(f => allowedImageTypes.includes(f.type.split('/')[1])),
          videos: file.filter(f => allowedVideoTypes.includes(f.type.split('/')[1])),
        },
        btn_id: selectedBtn,
        verbiage_id: selectedVerbiage?._id || "",
      }
      let res = await CallPOSTFORMAPI('script/verbiage_add', send_data, file);
      if (res?.data?.status) {
        toast.success("verbiage Added Successfully");
        FormRef.current.reset();
        setValidated(false);
        setCheckBox(false);
        setSelectedVerbiage(null)
        fetch();
        setButtonRows([{ title: "" }]);
        setData("");
        setFile([]);
        setSelectedBtn("");
      } else {
        toast.error("Something went wrong.  please try again");
      }
      setLoading({ msg: "", loading_status: false });
    }

    setoLoader(false);
    
  }
  const handleBtnChange = (e, index) => {
    const newTitle = e.target.value;
    if (/^\s*$/.test(newTitle)) {
      let buttonAllRows = [...buttonRows];
      buttonAllRows[index].title = '';
      setButtonRows(buttonAllRows);
    } else {
      let buttonAllRows = [...buttonRows];
      buttonAllRows[index].title = newTitle;
      setButtonRows(buttonAllRows);
    }
  }
  

  const handleVerbiageChange = (data) => {
    setSelectedVerbiage(data);
    setSelectedBtn("");
  }

  const handleRadio = (e) => {
    let checkedVal = e.target.value;
    setSelectedBtn(checkedVal);
  }

  const handleCheckBox = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setCheckBox(true);
      // setButtonRows([]);
    } else {
      setCheckBox(false)
      // setButtonRows([{title:""}]);
    }
  }

  useEffect(() => {
    fetch();
  }, []);


  return (
    <>
      <Header />
      <section className="all-scripts py-4">

        {/* top heading */}
        <div className="top-heading bg-light-gray py-4">
          <div className="container d-flex justify-content-between align-items-center">



            <h5 className='text-gray-heading' style={{ textTransform: 'capitalize', fontSize: '16px' }}>

              {/* <Link to="/dashboard" ><BackIcon/></Link> */}
              <button className='btn text-primary' type="button"
                onClick={() => navigate(-1)}
              ><BackIcon /></button>

              <span className="text-muted" style={{ marginTop: '12px' }}>{ScriptDetails?.script_title}</span> <AngleRight />
              <span className="btn text-primary " style={{ marginTop: '3px' }} > Add New Verbiage</span>
            </h5>
          </div>
        </div>


        <div className="container">
          <div className="main-form pt-4" style={{position:'relative'}} >
            {/* verbiage_title */}
        {oLoader && <div className="form-loader" >
          <div class="uploading-animation form-loader" id="uploading-animation"  > 
          </div>
          <span className=''>Uploading...</span> 
          </div>}

            <Form noValidate validated={validated} onSubmit={handleSubmit} id="create-verbiage"
              ref={FormRef}
            >
              {verbiageList.length > 0 ?
                <>
                  <div className="form-group relative mb-5">
                    <ScriptSelector
                      optionList={verbiageList}
                      value={selectedVerbiage}
                      setResult={handleVerbiageChange}
                    />
                  </div>
                  {(selectedVerbiage && selectedVerbiage?.btn_arr?.length > 0) ?
                    <div className='d-flex my-4' >
                      <div className="view-button-group">
                        {selectedVerbiage?.btn_arr?.map((btn, index) => (
                          <div className="form-group" key={index}>
                            {parseInt(btn?.is_created) === 1 ? <>
                              <input type="radio"
                                name={'verbiage_btn' + btn?._id}
                                className="btn-check disabled" id={btn?._id} value={btn?._id} autoComplete="off"
                                checked={btn?.is_created}
                              />
                              <label className="btn btn-outline-primary px-3 py-2 disabled" htmlFor={btn?._id} >{btn?.title}</label>
                            </>
                              :
                              <>
                                <input type="radio" name='verbiage_btn' className="btn-check" id={btn?._id} value={btn?._id} autoComplete="off"
                                  onChange={handleRadio}
                                  checked={btn?._id === selectedBtn}
                                />
                                <label className="btn btn-outline-primary px-3 py-2" htmlFor={btn?._id} >{btn?.title}</label>
                              </>
                            }
                          </div>))
                        }
                      </div>
                    </div>
                    : ""}
                </>
                : null}

              <Form.Group className="form-group mt-4 mb-4" controlId="verbiageTitle">
                {/* <Form.Label>Verbiage Title</Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="Enter the New Verbiage Title"
                  name="verbiage_title"
                  className="form-control"
                  required
                  ref={verbiate_title_ref}
                  isInvalid={validated}
                  onChange={(e)=> {
                    if(e.target.value.length > 0){
                      setValidated(false)  
                    }else{
                      setValidated(true)
                      verbiate_title_ref.current.focus();
                    }
                  }}    
                />
                {validated ?
                  <Form.Control.Feedback type="invalid">
                    Please enter a verbiage title.
                  </Form.Control.Feedback>
                  : ""}
              </Form.Group>


              {/* <input type="text" ref={descriptionRef} height={'2px'}
              style={{height: '1px'}}
              />  */}
              {/* ref={descriptionRef} */}
              <div className='' id="TEXT_FOCUS"   >
            
              <MyCkeditor
                name="verbiage_description"
                onChange={(description) => {
                  setData(description);
                  setVerbiageDescriptionError(''); 
                }}
                editorLoaded={editorLoaded}
                value={data}
                checkRef={descriptionRef}
              />
             
              {verbiageDescriptionError && (
                <div className="text-danger">{verbiageDescriptionError}</div>
              )}
          </div>

              <Form.Group className='my-3'>
{/* onClick={() => FileRef.current.click()} 
  ref={FileRef}
*/}
                <div className='uploadImages-box border rounded d-flex justify-content-center align-items-center' >
                  <label htmlFor='file_upload_module' id="upload-lable"  className='d-flex justify-content-center flex-column align-items-center'>
                    <BiImageAdd fontSize={38} />
                    <p>Upload Images/Videos here</p>
                  </label>
                  <input type="file" hidden accept="image/* video/*"
                    multiple
                    id='file_upload_module'
                     onChange={handleUpload}
                      />
                </div>

                <RenderFileInput file={file} removeThisImage={removeThisImage}/>
              </Form.Group>

              <Form.Group className="mb-4">
                <label htmlFor='set_up_next' className="d-flex" style={{ justifyContent: 'left', marginTop: '20px' }}>
                  <input type="checkbox" onChange={handleCheckBox} name="set_up_next" id="set_up_next" checked={checkbox} />
                  <span>Customer Response </span>
                </label>
              </Form.Group>


              {/* !checkbox */}
              {buttonRows?.length === 0 ?
                <div className="text-end mt-3"><button type="button" className='btn text-primary p-0' onClick={() => handleAddButton()}>+ Add Button</button>
                </div> : ""}

              {buttonRows?.map((button, index) => (

                // button form group
                <div className="form-group mb-3" key={index}>
                  <input type="text" name='button_name' placeholder='Enter Button Name' className='form-input text-gray-light'
                    value={button?.title}
                    // setButtonRows((prevState)=>([...prevState, {name: e.target.value} ])
                    onChange={(e) => handleBtnChange(e, index)}
                  />
                  <div className="text-end mt-3">
                    {/* add button */}
                    {index === buttonRows.length - 1 && ( // Add this condition to show "Add More" only for the last entry
              <button
                type="button"
                className="btn text-primary p-0"
                onClick={handleAddButton}
              >
                + Add More
              </button>
            )}
                    <button type="button" className='btn text-danger p-0 ms-4' onClick={() => handleRemoveButton(index)}>Remove</button>

                  </div>
                </div>

              ))}



              <div className='form-group  btn-section d-flex gap-4 align-content-center '  >
                {/* <button className='btn btn-primary' disabled={loading.loading_status} type='submit'> 
                            {loading.loading_status ? 'Loading...' : 'Save'} 
                          </button> */}


                <button className='primaryBtn fs-8' disabled={loading?.loading_status}  >
                  {loading?.loading_status ? "Loading" : "Continue"}
                </button>

                {verbiageList.length > 0 ?
                  <Link style={{ textDecoration: 'none' }} className='primaryBtn fs-8' type="button" to={`/script-details/${id}`} >View Verbiage</Link>
                  : ""}

              </div>
            </Form>


          </div>
        </div>

      </section>

    </>
  )
}


export default CreateVerbiage;