// import React from 'react';


// function VerbiageCard({item,getVeriageById,index}) {
//     const [isChecked,setisChecked] = React.useState(false);

//   return (
//     <>
//     <div className="card-body">
//         <h5 className="card-title">{item?.verbiage_title}</h5>
//         <hr/>
//         <p className="card-text">{item?.verbiage_description}</p>

//         <div className='d-flex gap-2' >

//             {item?.btn_arr?.length > 0 &&  item?.btn_arr.map((btn,i)=>(
//             <div key={i}>
//                 <input type="radio" 
//                 className="btn-check" 
//                 name={`verbiage_btn_`+item?._id}  
//                 id={btn?._id} value={btn?._id} 
//                 autoChecked={false}
//                 onChange={(e)=>{
//                     setisChecked(!isChecked)
//                     getVeriageById(e,index)
//                 }}
//                 />
//                 <label className="btn btn-outline-primary px-3 py-2" htmlFor={btn?._id} >{btn?.title}</label>
//             </div>
//             ))}
//         </div> 
//     </div>
//     </>
//   )
// }

// export default VerbiageCard



import React, { useState } from 'react';
import { InfoIcon } from '../../helpers/Icons';
import ViewFiles from '../ScriptForm/ViewFiles';
import { Modal } from 'react-bootstrap';
import { BASE_IMG } from '../../helpers/BaseFn';
import MyLightBox from './MyLightBox';
import { useEffect } from 'react';

function VerbiageCard({ item, getVeriageById, index }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [Vlist,setViList] = useState([]);
  const handleOptionChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    getVeriageById(e, index);
  };
  useEffect(()=>{
    const images = item?.media?.images || [];
    const videos = item?.media?.videos || [];
      const combinedMedia = [
        ...images.map((image) => ({
          url: BASE_IMG + image,
          src: BASE_IMG + image,
          type: 'image',
          altTag: 'Image',
        })),
        ...videos.map((video) => ({
          url: BASE_IMG + video,
          src: BASE_IMG + video,
          ...{
              type: "video",
              title: "Big Buck Bunny",
              description:
                "The Peach Open Movie Project\n\nBlender Institute, Netherlands",
              width: 1280,
              height: 720,
              // poster:
              //   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
              sources: [
                {
                  src: BASE_IMG + video,
                  type: "video/mp4",
                },
              ],
            },
        })),
      ];
      // console.log({combinedMedia});
      setViList(combinedMedia);
  
  },[item?.images,])

  return (
    <>
      <div className="card-body">

        <div className='d-flex justify-content-between'>
          <strong className="card-title">{item?.verbiage_title}</strong>
          <div onClick={() => setOpenModal(true)} className='action-icon link text-primary ' title="View Verbiage Details" style={{ width: '30px', display: 'inline-block',cursor:'pointer' }}>
            <InfoIcon />
          </div>
        </div>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: item?.verbiage_description }}></p>
        <div className='d-flex gap-2'>
          {item?.btn_arr?.length > 0 &&
            item?.btn_arr.map((btn, i) => (

              parseInt(btn?.status) ?
                <div key={i}>
                  <input
                    type="radio"
                    className="btn-check"
                    name={`verbiage_btn_${item?._id}`}
                    id={`verbiage_btn_${item?._id}_${btn?._id}`}
                    value={btn?._id}
                    checked={selectedOption === btn?._id}
                    onChange={handleOptionChange}
                  />
                  <label
                    className="btn btn-outline-primary px-3 py-2"
                    htmlFor={`verbiage_btn_${item?._id}_${btn?._id}`}
                  >
                    {btn?.title}
                  </label>
                </div>
                : ""
            ))}
        </div>
      </div>


      <MyLightBox
        open={openModal}
        setOpen={setOpenModal}
        srcList={Vlist}
      />
      {/* <Modal show={openModal} onHide={() => setOpenModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Documents</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="mx-auto my-4 d-flex justify-content-between flex-wrap">
      {item?.media?.images.length > 0 || item?.media?.videos.length > 0 ? (
        // If there are images or videos, display them
        <>

          {item?.media?.images.map((item) => (
            <img src={BASE_IMG + '' + item} width={225} className='p-2 ' />
          ))}
          {item?.media?.videos.map((item) => (
            <video src={BASE_IMG + '' + item} className='p-2' controls={true} width={300} />
          ))}
        </>
      ) : (
        
        <p>No documents</p>
      )}
    </div>
  </Modal.Body>
</Modal> */}

    </>
  );
}

export default VerbiageCard;


