import React from 'react';

import DataGrid, { Scrolling, Pager, Paging, Column,SearchPanel } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ScriptDetailsDrop from '../dropdowns/ScriptDetailsDrop';
import { ViewIcon } from '../../helpers/Icons';

const SubAdminDashboardTbl = ({data}) => {
    const RenderAction = (e) => {
        const item = e.data;
        return (
<>
            <Link to={"/sub-admin/script-details/" + item?._id} 
											
											className='action-icon link text-primary ' title="View Script Details"
											
											 style={{ width: '30px', display: 'inline-block' }}>
												
												<ViewIcon />
											</Link>
											&nbsp; &nbsp;
                                            </>
        );
    }

    const renderTitle = (e)=>{
        return  (

            <Link
                              to={"/sub-admin/script-details/" + e?.data?._id}
                              className="btn link text-primary text-truncate-720"
                              title={e?.value}
                            >
                              {e?.value}
                            </Link>

        )
    }
    const renderDate = (e)=>{
        return (moment(e?.value).format('DD MMM YYYY'))
    }
    const allowedPageSizes = [5, 10, 'all'];
  return (
    <>
    
    
    <DataGrid
          id='gridContainer'
          dataSource={data}
          keyExpr="_id"
        //   showBorders={true}
            showBorders={false}
        >
          {/* <Column dataField="_id" caption="ID" /> */}
          <Column dataField="script_title" caption="Script Title" cellRender={renderTitle} />
          <Column dataField="createdAt" caption="Created Date" cellRender={renderDate} />
          <Column dataField="_id" caption="Action" cellRender={RenderAction} width={100} />

          <Scrolling rowRenderingMode='virtual'></Scrolling>
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={true}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
             /> 
          <Paging defaultPageSize={10} />
          <SearchPanel  visible={true}/>
    </DataGrid>
    </>
  )
}

export default SubAdminDashboardTbl