import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { BiBlock } from 'react-icons/bi';
import { CgUnblock } from 'react-icons/cg';
import {
  BlockAgent,
  GetAllAgents,
  UnBlockAgent,
} from '../../../helpers/BaseFn';
import { BackIcon, EditIcon } from '../../../helpers/Icons';
import TableSkeleton from '../../../components/tables/TableSkeleton';
import HeaderSubAdmin from '../SubAdminHeader';
import EditAgentModal from './EditAgentModal';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../../redux/Slices/LoadingSlice';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AgentTbl from './AgentTbl';
import AddAgentModal from './AddAgentModal';

const AgentList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [subAdminList, setSubAdminList] = useState([]);
  const dispatch = useDispatch();
  const [activeAgents, setActiveAgents] = useState([]);
  const [blockedAgents, setBlockedAgents] = useState([]);
  const [showActiveAgents, setShowActiveAgents] = useState(true);

  const LoadPage = async () => {
    setLoading(true);
    dispatch(setReduxLoading(true));
    let result = await GetAllAgents();
    setSubAdminList(result);
    setLoading(false);
    dispatch(setReduxLoading(false));
  };

  const HandleBlock = async (id) => {
    const result = await BlockAgent(id);
    console.log({result})
    if (result?.status) {
      toast.success('Blocked Successfully');
      LoadPage();
    } else {
      toast.error(result?.msg);
    }
  };

  const HandleUnBlock = async (id) => {
    const result = await UnBlockAgent(id);
    if (result?.status) {
      toast.success('Un-Blocked Successfully');
      LoadPage();
    } else {
      toast.error(result?.msg);
    }
  };

  useEffect(() => {
    LoadPage();
  }, []);

  useEffect(() => {
    
    const activeAgentsList = subAdminList.filter((agent) => !agent.isBlocked);
    const blockedAgentsList = subAdminList.filter((agent) => agent.isBlocked);

    setActiveAgents(activeAgentsList);
    setBlockedAgents(blockedAgentsList);
  }, [subAdminList]);

  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [useData, setUseData] = useState({});

  const [activeAgentsPage, setActiveAgentsPage] = useState(1);
  const [blockedAgentsPage, setBlockedAgentsPage] = useState(1);
  const [openAddAgentModal,setOpenAddAgentModal] = useState(false);
  const [fetchLoading,setFetchLoading]   = useState(false);

  const itemsPerPage = 10; 

  const handleActiveAgentsPageChange = (newPage) => {
    setActiveAgentsPage(newPage);
  };

  const handleBlockedAgentsPageChange = (newPage) => {
    setBlockedAgentsPage(newPage);
  };

  const activeAgentsStartIndex = (activeAgentsPage - 1) * itemsPerPage;
  const activeAgentsEndIndex = activeAgentsStartIndex + itemsPerPage;
  
  const blockedAgentsStartIndex = (blockedAgentsPage - 1) * itemsPerPage;
  const blockedAgentsEndIndex = blockedAgentsStartIndex + itemsPerPage;

  return (
    <>
      <HeaderSubAdmin />
      <main>
        <section className="all-scripts py-4">
          <div className="top-heading bg-light-gray py-4">
            <div className="container d-flex justify-content-start align-items-center">
              <h4 className="text-gray-heading text-capitalize d-flex justify-content-between w-full align-items-center" style={{width:'100%'}}>
                <span>
                <button
                  className="btn text-primary"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <BackIcon />
                </button>
                Agents Detail
                </span>
                <button className='btn text-primary ' type="button"  onClick={()=>setOpenAddAgentModal(true)} >
                  + Add Agent
                </button>
              </h4>
            </div>
          </div>

          <div className="container">
            <Tabs className={'mt-5'}>
              <TabList>
                <Tab onClick={() => setShowActiveAgents(true)}>
                  Active agents
                </Tab>
                <Tab onClick={() => setShowActiveAgents(false)}>
                  Blocked agents
                </Tab>
              </TabList>
              <TabPanel>
              {loading ? <TableSkeleton /> : ''}
                  <AgentTbl 
                    data={activeAgents}
                    HandleBlock={HandleBlock}
                    setUseData={setUseData}
                    setEditModalOpen={setEditModalOpen}
                  />
              </TabPanel>
              <TabPanel>
              {loading ? <TableSkeleton /> : ''}
                  <AgentTbl 
                    data={blockedAgents}
                    HandleUnBlock={HandleUnBlock}
                    setUseData={setUseData}
                    setEditModalOpen={setEditModalOpen}
                  />
              </TabPanel>
            </Tabs>
            <EditAgentModal
              show={EditModalOpen}
              handleClose={() => setEditModalOpen(false)}
              useData={useData}
              refreashData={LoadPage}
              // fetchLoading={fetchLoading}
            />

            <AddAgentModal
              show={openAddAgentModal}
              handleClose={()=>setOpenAddAgentModal(false)}
              refreashData={LoadPage}

            />
          </div>
        </section>
      </main>
    </>
  );
};

export default AgentList;
