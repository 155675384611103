
import React, { useEffect, useRef, useState } from 'react';
import { CallGETAPI, CallPOSTAPI, CallPOSTFORMAPI } from '../../helpers/API';
import { toast } from 'react-toastify';
import ScriptSelector from '../../components/ScriptForm/ScriptSelector';
import { Form } from 'react-bootstrap';
import { BASE_IMG, GetScriptDetail } from '../../helpers/BaseFn';
import ToogleSwitch from '../toggle/ToogleSwitch';
import MyCkeditor from '../ckeditor/MyCkeditor';
import { BiImageAdd } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';


function EditVerbiage({ handleClose, scriptDetails, id, verbiageDetails,image,video }) {
  const imgs = verbiageDetails?.media?.images
  const vids = verbiageDetails?.media?.videos
  const [images, setImages] = useState(imgs)
  const [videos, setvideos] = useState(vids)
  useEffect(()=>{
    setImages(imgs);
    setvideos(vids);
  },[verbiageDetails]);
 

  const FormRef = useRef();
  const verbiate_title_ref = useRef();
  const [buttonRows, setButtonRows] = useState(verbiageDetails?.btn_arr || []);
  const [verbiageList, setVerbiageList] = useState([]);
  const [selectedVerbiage, setSelectedVerbiage] = useState(null);
  const [selectedBtn, setSelectedBtn] = useState("");
  const [validated, setValidated] = useState(false);

  const [verbiageTitle, setVerbiageTitle] = useState(verbiageDetails?.verbiage_title || "");
  const [verbiageDesc, setVerbiageDesc] = useState(verbiageDetails?.verbiage_description || "");

  const [checkbox, setCheckBox] = useState(false);
  const [data, setData] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [file, setFile] = useState([])
  const FileRef = useRef(null)
  const [verbiageDescriptionError, setVerbiageDescriptionError] = useState('');
  const descriptionRef = useRef(null);
  const handleUpload = e => {
    const files = Array.from(e.target.files);

    // Filter files to only include images and videos
    const allowedFileTypes = ['image/jpeg', 'image/png', 'video/mp4', 'video/quicktime','video/webm'];
    const filteredFiles = files.filter(file => allowedFileTypes.includes(file.type));

    // If any files were filtered out, show an error message
    if (filteredFiles.length !== files.length) {
      toast.error("Only image (JPEG, PNG) and video (MP4, MOV) files are allowed.");
    }
    const fileLen = files?.length +file?.length; 
    if(fileLen  > 10){
      toast.error("More then 10 files not allowed");
      return '';
    }

    setFile(prev => [...prev, ...filteredFiles]);
  }
 

  const removeThisImage = (i) => {
    let newValues = [...file];
    newValues.splice(i, 1);
    setFile(newValues);
  };
  const removeThisUploadedImage = (i) => {
    let newValues = [...images];
    newValues.splice(i, 1);
    setImages(newValues);
  };
  const removeThisUploadedVideo = (i) => {
    let newValues = [...videos];
    newValues.splice(i, 1);
    setvideos(newValues);
  };


  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const [loading, setLoading] = useState({
    loading_status: false,
    msg: '',
  });
  const [btnLenthWithOutContinue,setBtnLenthWithOutContinue] = useState(0);
  const handleAddButton = () => {
    let buttonAllRows = [...buttonRows]
    let newRow = { title: '', status: '1', is_created: '0', no_objection: '0' }
    buttonAllRows.push(newRow);
    const checkBtnCount = buttonAllRows?.filter((item)=> item.title!='Continue');
    console.log({checkBtnCount,buttonAllRows})
    setBtnLenthWithOutContinue(checkBtnCount?.length || 0)
    setButtonRows(buttonAllRows);
  }

  const handleRemoveButton = (index) => {
    // get all button rows
    let buttonAllRows = [...buttonRows];
    // Remove the object at the found index
    buttonAllRows.splice(index, 1);
    // set all button rows

    const checkBtnCount = buttonAllRows?.filter((item)=> item.title!='Continue');
    setBtnLenthWithOutContinue(checkBtnCount?.length || 0)
    setButtonRows(buttonAllRows);
  }
  const [ScriptDetails, setScriptDetails] = useState({});
  const [selectedScript, setSelectedScript] = useState([]);
  const fetch = async () => {
    if (!id) return "";
    const res = await CallGETAPI('script/get_verbiage_data?script_id=' + id);
    const result = res?.data?.data || [];

    const opt_val = [];
    const optioan_val = result.map((item) => {
      // let no_objection_btn = item.btn_arr.find((it)=>parseInt(it?.no_objection)===1);

      // if(no_objection_btn){
      //   setCheckBox(true)
      // }
      let findBtn = item.btn_arr.find((it) => it?._id === verbiageDetails?.btn_id)
      if (findBtn) {
        opt_val.push(item);
      }
      // buttonRows
      return ({ ...item, label: item.verbiage_title, })
    })
    setSelectedVerbiage(opt_val?.[0])

    setSelectedScript(result);
    // let optioan_val = result.map((item)=>({...item,label:item.verbiage_title,}))
    let dropDownScriptData = await GetScriptDetail(id);
    setScriptDetails(dropDownScriptData);
    setVerbiageList(optioan_val)
  }

  const [oLoader,setoLoader] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading({ msg: "", loading_status: true });
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setLoading({ msg: "", loading_status: false });
      setValidated(true);
      verbiate_title_ref.current.focus();
      e.stopPropagation();
    } else {

      let btnArr = [...buttonRows];

      let FilterBtn = btnArr.filter((item) => item.title.trim() !== "");
      // {title:'Next'}
      // let  checked  = e.target.set_up_next.value;
      let check_continue_is_created = verbiageDetails?.btn_arr.find((ite) => (parseInt(ite.is_created) === 1 && parseInt(ite.no_objection) === 1))
      if (checkbox) {
        if (check_continue_is_created) {
          FilterBtn.push(check_continue_is_created);
        } else {
          FilterBtn.push({ title: 'Continue', no_objection: 1, is_created: 0,status:1});
        }
      }


      if (!verbiageDesc) {
        setVerbiageDescriptionError('  Please enter a verbiage description.');
        setLoading({ msg: '', loading_status: false });
        descriptionRef.current = Math.random(10,1000);
        return '';
      } else {
        setVerbiageDescriptionError(''); // Clear the error message when description is filled.
      }
      setoLoader(true);
      let send_data = {
        verbiage_title: e.target.verbiage_title.value,
        script_id: id,
        media: {
          images: images,
          videos: videos,
        },
        verbiage_description: verbiageDesc,
        btn_arr: FilterBtn,
        btn_id: verbiageDetails.btn_id,//selectedBtn,
        verbiage_id: selectedVerbiage?._id || "",
        old_media: {
          images:images || [],
          videos:videos || [],
        }
      }
      // return "";
      let res = await CallPOSTFORMAPI('script/edit_verbiage?verbiage_id=' + verbiageDetails?._id, send_data, file,{},true);
      if (res?.data?.status) {
        toast.success("Verbiage Updated Successfully");
        // document.getElementById("create-verbiage").reset();
        // FormRef.current.reset();
        setValidated(false);
        handleClose(true)
        // setSelectedVerbiage(null)
        // fetch();
        // setButtonRows([{title:"",is_created:0}])
      } else {
        toast.error("Something went wrong.  please try again");
      }
      setLoading({ msg: "", loading_status: false });
      setoLoader(false);
    }

  }
  // const handleBtnChange = (e, index) => {
  //   let buttonAllRows = [...buttonRows];
  //   buttonAllRows[index].title = e.target.value;
  //   setButtonRows(buttonAllRows);
  // }

  const handleBtnChange = (e, index) => {
    const newTitle = e.target.value;
    if (/^\s*$/.test(newTitle)) {
      let buttonAllRows = [...buttonRows];
      buttonAllRows[index].title = '';
      setButtonRows(buttonAllRows);
    } else {
      let buttonAllRows = [...buttonRows];
      buttonAllRows[index].title = newTitle;
      setButtonRows(buttonAllRows);
    }
  }
  
  
  
  const handleVerbiageChange = (data) => {

    setSelectedVerbiage(data);
    setSelectedBtn("");
  }

  const handleRadio = (e) => {
    let checkedVal = e.target.value;
    setSelectedBtn(checkedVal);
  }

  /**        let is_checked = 0;
        // && parseInt(it.is_created)===1
        let checkCondition  = verbiageDetails?.btn_arr?.find((it)=> (it.title==='Continue'));
        if(checkCondition){
          is_checked = 1;
        }
        setIsChecked(is_checked); */
  const handleCheckBox = (e) => {
    const checked1 = e.target.checked || 0;


    let checkCondition = verbiageDetails?.btn_arr?.find((it) => (it.title === 'Continue' && parseInt(it.is_created) === 1));
    if (checkCondition) {
      return '';
    }

    const oldBtns = [...buttonRows];
    if(!checked1){
      const newArr = oldBtns.filter((item) => item.title !== 'Continue');
      setButtonRows(newArr);
    }
    // if (verbiageDetails?.btn_arr?.some((btn) => btn.title === 'Continue' && verbiageDetails?.btn_arr?.some((btn) => btn.is_created === 1))) {
    //   return;
    // }
    setCheckBox(checked1);
    setIsChecked(checked1);
  };


  useEffect(() => {
    fetch();
  }, [])

  const [isChecked, setIsChecked] = useState(0);
  const handleButtonDesable = (e, i) => {

    let aar = [...buttonRows];
    aar[i].status = e.value === false ? 0 : 1;
    setButtonRows(aar);
  }


  useEffect(() => {
    setVerbiageTitle(verbiageDetails?.verbiage_title)
    setVerbiageDesc(verbiageDetails?.verbiage_description)
    let btns = verbiageDetails?.btn_arr?.filter((item) => parseInt(item?.no_objection) != 1);
    let Findbtns = verbiageDetails?.btn_arr?.find((item) => parseInt(item?.no_objection) === 1);
    if (Findbtns) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
    setButtonRows(btns || [{ title: "", is_created: 0, status: 0, no_objection: 0 }])
    // setDescription(scriptDetails?.script_description)

    const checkBtnCount = verbiageDetails?.btn_arr?.filter((item)=> item.title!='Continue');
    setBtnLenthWithOutContinue(checkBtnCount?.length || 0);
    let is_checked = 0;
    // && parseInt(it.is_created)===1
    let checkCondition = verbiageDetails?.btn_arr?.find((it) => (it.title === 'Continue'));
    if (checkCondition) {
      is_checked = 1;
    }
    setIsChecked(is_checked);



  }, [verbiageDetails])

  //   useEffect(()=>{
  //     if(!selectedScript.length){ return "" }
  //     let opt_val = [];
  //     let optioan_val  = selectedScript.map((item)=>{
  //         let findBtn = item.btn_arr.find((it)=>it?._id===verbiageDetails?.btn_id)
  //         if(findBtn){
  //             opt_val.push(item);
  //         }

  //         return ({...item,label:item.verbiage_title,})
  //     })
  //   },[verbiageDetails])
  return (
    <>
      <section className="all-scripts py-8">


        <div className='' >


          {/* <Form noValidate validated={validated} onSubmit={submitHandler} id="edit-script-title" >
            <Form.Group controlId="title" className='mb-3'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={handleTitleChange}
                    required
                />
                <Form.Control.Feedback type="invalid">
                Please enter a title.
                </Form.Control.Feedback>
            </Form.Group>

            <button className='primaryBtn fs-5' disabled={loading?.loading_status}  >{loading?.loading_status?"Loading":"Next"}</button>
            </Form> */}

{oLoader && <div className="form-loader" >
          <div class="uploading-animation form-loader" id="uploading-animation"  > 
          </div>
          <span className=''>Uploading...</span> 
          </div>}
          <Form noValidate validated={validated} onSubmit={handleSubmit} id="create-verbiage"
            ref={FormRef}
          >


            <Form.Group className="form-group mt-4 mb-4" controlId="verbiageTitle">
              <Form.Label>Verbiage Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the New Verbiage Title"
                name="verbiage_title"
                className="form-control"
                required
                ref={verbiate_title_ref}
                isInvalid={validated}
                value={verbiageTitle}
                onChange={(e) =>{ setVerbiageTitle(e.target.value)
                  if(e.target.value.length > 0){
                    setValidated(false)  
                  }else{
                    setValidated(true)
                    verbiate_title_ref.current.focus();
                  }
                }


                
          }

              />
              {validated ?
                <Form.Control.Feedback type="invalid">
                  Please enter a verbiage title.
                </Form.Control.Feedback>
                : ""}
            </Form.Group>
            <div className='' id="TEXT_FOCUS">
              <MyCkeditor
                name="verbiage_description"
                onChange={(description) => {
   
      if (description || data) {
        setVerbiageDescriptionError('');
      } else {
        setVerbiageDescriptionError('Please enter a verbiage description.');
      }

      setVerbiageDesc(description);
    }}
                editorLoaded={editorLoaded}
                value={verbiageDesc||data}
                checkRef={descriptionRef}
              />
              {verbiageDescriptionError && (
                <div className="text-danger">{verbiageDescriptionError}</div>
              )}
            </div>
            <Form.Group className='my-3 edit-img'>
              <div className=' d-flex flex-wrap gap-3 mt-4'>
                {
                  images?.map((item, i) => (<div className='verbiage-img-upload mb-3' key={i}>
                    <img src={BASE_IMG + '' + item} alt="" />
                    <AiFillDelete onClick={() => removeThisUploadedImage(i)} className='delete-img' />
                  </div>)
                  )
                }
                {
                  videos?.map((item, i) => (<div className='verbiage-img-upload mb-3' key={i}>
                    <video src={BASE_IMG + '' + item} alt="" controls />
                    <AiFillDelete onClick={() => removeThisUploadedVideo(i)} className='delete-img-video' />
                  </div>)
                  )
                }
              </div>
              <div className='uploadImages-box border rounded d-flex justify-content-center align-items-center' >
                <div onClick={() => FileRef.current.click()} className='d-flex justify-content-center flex-column align-items-center'>
                  <BiImageAdd fontSize={38} />
                  <p>Upload Images/Videos here</p>
                </div>
                <input type="file" hidden accept="image/* video/*"
                  multiple
                  ref={FileRef} onChange={handleUpload} />
              </div>

              <div className=' d-flex flex-wrap gap-3 mt-4'>
                {
                  file?.map((files, i) => {
                    return files?.type?.slice(0, 5) == "image" ? (<div className='verbiage-img-upload' key={i}>

                      <img src={URL.createObjectURL(files)} alt="" />
                      <AiFillDelete onClick={() => removeThisImage(i)} className='delete-img' />
                    </div>) : (<div className='verbiage-img-upload' key={i}>
                      <video controls src={URL.createObjectURL(files)} alt="" />
                      <AiFillDelete onClick={() => removeThisImage(i)} className='delete-img-video' />
                    </div>)
                  }
                  )
                }
              </div>
            </Form.Group>

            {
              <Form.Group className="mb-4">
                <label htmlFor='set_up_next' className="d-flex" style={{ justifyContent: 'left', marginTop: '20px' }}>
                  <input
                    type="checkbox"
                    onChange={handleCheckBox}
                    name="set_up_next"
                    id="set_up_next"
                    // checked={checkbox || (verbiageDetails?.btn_arr?.some(btn => parseInt(btn.is_created) === 1) && !checkbox)}

                    checked={isChecked}
                  />
                  <span>Customer Response</span>
                </label>
              </Form.Group>

            }
{/* {buttonRows.length===0  || (buttonRows.length===1 && buttonRows[0]?.title==='Continue') ?  */}
 {/* :""} */}
{buttonRows?.map((button, index) => (button?.title!=='Continue') && (
  <div className="form-group mb-3" key={index}>
    <input
      type="text"
      name="button_name"
      placeholder="Enter Button Name"
      className="form-control form-input text-gray-light"
      value={button?.title}
      onChange={(e) => handleBtnChange(e, index)}
    />
    <div className="text-end mt-3 d-flex" style={{ justifyContent: 'space-between' }}>
      <ToogleSwitch
        switchKeyValue={parseInt(button?.status)}
        setSwitchValue={(e) => handleButtonDesable(e, index)}
        switchValue={parseInt(button?.status)}
        switchKey={'account_site_main_site'}
      />
      <div className=''>
        {/* {index === btnLenthWithOutContinue - 1 && ( // Show the "Add More" button for the last button field
          <button type="button" className='btn text-primary p-0' onClick={() => handleAddButton()}>+ Add More</button>
        )}   */}
        {parseInt(button?.is_created) === 1 ? (
          ""
        ) : (
          <button type="button" className='btn text-danger p-0 ms-4' onClick={() => handleRemoveButton(index)}>Remove</button>
        )}
      </div>
    </div>
  </div>
))}

{/* {buttonRows.length===0 &&  */}
<button type="button" className='btn text-primary p-0 d-flex justfiy-content-right'  style={{width:'100%',
    justifyContent: 'right'
}}  onClick={() => handleAddButton()}>+ Add More</button>
{/* } */}

            <div className='form-group  btn-section' >
              <button className='primaryBtn fs-8' disabled={loading?.loading_status}  >
                {loading?.loading_status ? "Loading" : "Update"}
              </button>
            </div>
          </Form>

        </div>

      </section>
    </>
  )
}

export default EditVerbiage;