import React from 'react';
import DataGrid, { Scrolling, Pager, Paging, Column,SearchPanel } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { EditIcon } from '../../../helpers/Icons';
import { BiBlock } from 'react-icons/bi';
import { CgUnblock } from 'react-icons/cg'

const  AgentTbl = ({data,HandleBlock,HandleUnBlock,setUseData,setEditModalOpen}) => {
    const renderName  = (e) =>{
        const item = e.data;
        return (
            <>
                
                {item?.first_name + ' ' + item?.last_name}
            </>
        )

    }
    const renderAction = (e) => {
        const item = e.data;
            return (
                <>
                    <div className="d-flex justify-content-center action-section">
                    <button
                        type="button"
                        className="action-icon btn link text-primary"
                        title="Edit Agent"
                        onClick={() => {
                        setUseData(item);
                        setEditModalOpen(true);
                        }}
                    >
                        <EditIcon />
                    </button>
                    {
                        item?.isBlocked ?(
                            <span
                            style={{ width: '25px' }}
                            className="action-icon"
                            type="button"
                            onClick={() =>
                            confirmAlert({
                                title: 'Unblock Agent',
                                message: 'Are you sure to do this.',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () =>
                                        HandleUnBlock(item?._id),
                                    },
                                {
                                    label: 'No',
                                    onClick: () => {},
                                },
                                ],
                            })
                            }
                            title="Unblock Agent"
                        >
                              <CgUnblock color="green" size={24} />
                        </span>
                        ):(
                            <span
                            style={{ width: '25px' }}
                            className="action-icon"
                            type="button"
                            onClick={() =>
                            confirmAlert({
                                title: 'Block Agent',
                                message: 'Are you sure to do this.',
                                buttons: [
                                {
                                    label: 'Yes',
                                    onClick: () =>
                                    HandleBlock(item?._id),
                                },
                                {
                                    label: 'No',
                                    onClick: () => {},
                                },
                                ],
                            })
                            }
                            title="Block Agent"
                        >
                            <BiBlock color="red" size={24} />
                        </span>



                        )
                        }
                    </div>
                </>
            )
    }
    const allowedPageSizes = [5, 10, 'all'];

  return (
    <>
    <DataGrid
          id='gridContainer'
          dataSource={data}
          keyExpr="_id"
          showBorders={true}
        >
            <Column dataField="name" caption="Name" cellRender={renderName} />
            <Column dataField="email" caption="Email" />
            <Column dataField="_id" caption="Action" cellRender={renderAction}  width={100} />

          <Scrolling rowRenderingMode='virtual' />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={true}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
             />

          <Paging defaultPageSize={10} />
          <SearchPanel  visible={true}/>
        </DataGrid>
    </>
  )
}

export default AgentTbl