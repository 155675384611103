import React from 'react'
import EditSubAdminForm from './EditSubAdminForm'
import { Modal } from 'react-bootstrap'

const EditSubAdminModal =  ({subAdminId,show,handleClose,refreashList}) =>  {
    // const subAdminId = 
  return (
    <>  
    <Modal show={show} onHide={handleClose} className="modal-lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subadmin</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight:'300px'}}>
            <EditSubAdminForm 
                subAdminId={subAdminId}
                refreashList={refreashList}
                handleClose={handleClose}
            />
        </Modal.Body>
    </Modal>
    </>
  )
}

export default EditSubAdminModal