import React, { useState } from 'react';
import './treview.scss';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { AddIcon, EditIcon } from '../../helpers/Icons';
import { AiOutlinePlus } from 'react-icons/ai';
import { getVerbiagebyId } from '../../helpers/BaseFn';

const TreeNode = ({ node, handleClick, is_first_child,parentEnabled }) => {
   const [isExpanded, setIsExpanded] = useState(true);

   const toggleExpand = () => {
      setIsExpanded(!isExpanded);
   };
   
   const isEnabled = parseInt(node.is_enable) === 1;
   const listItemClassName =  isEnabled && parentEnabled ? "" : "disabled-li"; // Add your CSS class name here
   const is_enable = isEnabled && parentEnabled;


   return (
      <li className={`child-node node-li-${is_first_child} ${listItemClassName}`}  >
         <span className='d-flex gap-2'>
            {node.children && node.children.length > 0 && <p className='' onClick={toggleExpand} >
               {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
               {/* {isExpanded ? '[-]' : '[+]'} */}
            </p>}
            <p>

            </p>
            <p onClick={(e) => {
               if(is_enable){
                  handleClick(node);
               }
               e.stopPropagation();
            }}>
               {node.name}
            </p>
         </span>
         {isExpanded && node.children && node.children.length > 0 && (
            <ul>
               {node.children.map((childNode) => (
                  <TreeNode key={childNode.id} node={childNode} handleClick={handleClick} is_first_child={0} parentEnabled={parentEnabled} />
               ))}
            </ul>
         )}
      </li>
   )
};
const TreeViewContainer = ({ list, TreeData, handleAddScript, getVerbiageDetails }) => {

   const [searchTerm, setSearchTerm] = useState('');
   const params = useParams();
   const { script_id } = params;
   const navigate = useNavigate();
   const id = script_id
   const [modalLoading, setModalLoading] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [openNoModal, setOpenNoModal] = useState(false)
   const [modalHead, setModalHead] = useState("")
   const [selectedVerbiage, setSelectedVerbiage] = useState("");
   const [selectedBtn, setSelectedBtn] = useState("");
   const [verbiagedata, setVerbiagedata] = useState();
   const [thisId, setThisId] = useState("")
   const [thisScriptId, setThisScriptId] = useState("")

   const handleClick = async (node) => {
      setModalLoading(true)
      const ver_id = list.find((item) => item?.btn_id === node.id)?._id;
      
      const verbiage_id = ver_id || node?.verbiage_id;  
      for (let i = 0; i < list.length; i++) {
         if (list[i]?.btn_id === node.parent) {
            setThisScriptId(list[i]?._id)
            break
         }
      }
      setModalHead(node?.name);
      setSelectedBtn(node?.id);
      setSelectedVerbiage(verbiage_id);

      setThisId(node.id)
      setThisScriptId(node.parent)
      if (!node?.children || node?.children?.length === 0) {
         setOpenNoModal(true)
      } else {
         setOpenModal(true)
         let id = ""
         for (let i = 0; i < list.length; i++) {
            if (list[i]?.btn_id === node.id) {
               id = list[i]?._id
               setThisId(list[i]?._id)
               break
            }
         }
         let result = await getVerbiagebyId(verbiage_id || node.id);
         setVerbiagedata(result)
      }

      setModalLoading(false)
   }
   return (
      <>
         <div className='' id="treev-view-container-12" >
            {/* <ul className='wtree'>
               <TreeNode node={TreeData} handleClick={handleClick} is_first_child={'1'} searchTerm={searchTerm} />
            </ul> */}

            <ul className='wtree'>
               {TreeData.map((TreeItem,i)=><TreeNode node={TreeItem} handleClick={handleClick} is_first_child={'1'} searchTerm={searchTerm} 
               parentEnabled={parseInt(TreeItem.is_enable)}
               />)}
            </ul>

            <Modal show={openNoModal} onHide={(e) => { setOpenNoModal(false) }}>
               <Modal.Header className='pb-0' closeButton>
                  <Modal.Title className='w-100'><div >
                     <h6 className='mb-0'>{modalHead}</h6>
                  </div>
                  </Modal.Title>
               </Modal.Header>
               <hr />
               <Modal.Body>
                  <div className="d-flex justify-content-end align-items-center c-pointer" onClick={() => navigate(`/create-verbiage/${id}?verbiage_id=${selectedVerbiage}&btnId=${thisId}`)} >
                     <AiOutlinePlus />{" Add More"}
                  </div>
                  <h6 className='my-4'>No Verbiage Found</h6>
               </Modal.Body>
            </Modal>
            <Modal show={openModal} onHide={(e) => { setOpenModal(false) }}>
               <Modal.Header className='pb-0' closeButton>
                  <Modal.Title className='w-100'><div className="d-flex justify-content-between align-items-center">
                     <h6 className='mb-0'>{modalHead}</h6>
                     <div className='d-flex gap-1' >
                        <button className='btn text-primary pe-0' type="button" onClick={() => getVerbiageDetails(thisId)} style={{ width: '40px' }} > <span style={{ width: '40px', color: "rgb(38, 138, 255)" }}
                           title='Edit Verbiage'
                        >
                           <EditIcon color="#d3dae4" />
                        </span> </button>
                        <button className='btn text-primary  px-0' type="button" onClick={() => handleAddScript(thisId, '', 0)} style={{ width: '28px' }}
                           title='Add verbiage'> <span style={{ width: '28px', color: "rgb(38, 138, 255)" }}>
                              <AddIcon />
                           </span>
                        </button>

                     </div></div>
                  </Modal.Title>
               </Modal.Header>
               <hr />
               <Modal.Body>
                  {
                     modalLoading ? (<>
                        <div className=''>
                           <div className="text-heading text-center py-3 row mb-3">
                              <Skeleton width="300px" height="40px" />
                              <Skeleton width="300px" height="30px" />
                           </div>
                           <div className="row text-gray-light text-center py-3 mb-3">
                              <Skeleton width="300px" height="40px" />
                              <Skeleton width="300px" height="30px" />
                           </div>
                        </div>
                     </>) : (
                        <>
                           <h6 style={{ color: "gray" }}>Title:</h6>
                           <h6 className='mb-3'>
                              {verbiagedata?.verbiage_title}
                           </h6>
                           <h6 style={{ color: "gray" }}>Description:</h6>
                           <p dangerouslySetInnerHTML={{
                              __html: verbiagedata?.verbiage_description
                           }}></p>
                           <div className="view-button-group mt-4 mb-2">
                              {verbiagedata?.btn_arr?.map((it, i) => (
                                 <div className="form-group" key={i} >

                                    <input type="checkbox" className="btn-check" id={"no-objection-btn_" + it?._id}
                                       checked={parseInt(it?.is_created)}
                                       autoComplete="off" readOnly
                                       disabled={it?.is_created == 1}
                                       onClick={() => handleAddScript(verbiagedata?._id, it?._id, parseInt(it?.is_created))}
                                    />

                                    <>
                                       {parseInt(it?.status) === 0 ? <label
                                          className="btn  btn-outline-warning px-3 py-2 "
                                          htmlFor={"no-objection-btn_" + it?._id}>{it?.title}</label> : <label
                                             className="btn  btn-outline-primary px-3 py-2 "
                                             htmlFor={"no-objection-btn_" + it?._id}>{it?.title}</label>}
                                    </>
                                 </div>
                              ))}
                           </div>


                        </>
                     )
                  }

               </Modal.Body>
            </Modal>
         </div>
      </>
   )
}

export default TreeViewContainer