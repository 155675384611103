
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { CallPOSTAPI } from '../helpers/API';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Register = () => {

    const [loading,setLoading] = useState({status:false,loading_status:false,msg:""});
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
    password: '',
    confirm_pass: '',
    phone_no: '',
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading({ status:false,loading_status:true,msg:"" });

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with registration logic 
      console.log(formData);
      const res = await CallPOSTAPI('api_auth/registration',formData);
      console.log(res?.data?.status);
      if(res?.data?.status){
        toast.success(res?.data?.message);
        navigate('/');
      }else{
        toast.error(res?.data?.message);

      }
      // Add your registration logic here
    } else {
      setErrors(validationErrors);
    }
    setLoading({ status:false,loading_status:false,msg:"" });
  };

  const validateForm = () => {
    const errors = {};
    // Add your validation logic here
    if (!formData.first_name) {
      errors.first_name = 'First name is required.';
    }
    if (!formData.last_name) {
      errors.last_name = 'Last name is required.';
    }
    formData.user_name = formData.first_name;
    // if (!formData.user_name) {
    //   errors.user_name = 'User name is required.';
    // }
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Invalid email address.';
    }
    if (!formData.password) {
      errors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long.';
    }
    if (formData.password !== formData.confirm_pass) {
      errors.confirm_pass = 'Passwords do not match.';
    }
    if (!formData.phone_no) {
      errors.phone_no = 'Phone number is required.';
    }
    return errors;
  };

  return (
    <>

<section className="all-scripts py-4">

{/* top heading */ }
      <div className="top-heading bg-light-gray py-4">
          <div className="container d-flex justify-content-between align-items-center">
              <h4 className='text-gray-heading'>Register</h4>
          </div>
      </div>


        <div className='container' >
        <Form onSubmit={handleSubmit} className='main-form'>

            <div className='row' > 
                <div className='col-md-6' > 
                    <Form.Group className="mb-2" controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        isInvalid={!!errors.first_name}
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.first_name}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='col-md-6' > 
                <Form.Group className="mb-2" controlId="last_name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    isInvalid={!!errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                    </Form.Control.Feedback>
                </Form.Group>

                </div>
            </div>


      {/* <Form.Group className="mb-2" controlId="user_name">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          type="text"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
          isInvalid={!!errors.user_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.user_name}
        </Form.Control.Feedback>
      </Form.Group> */}

      <Form.Group className="mb-2" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2" controlId="confirm_pass">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          name="confirm_pass"
          value={formData.confirm_pass}
          onChange={handleChange}
          isInvalid={!!errors.confirm_pass}
        />
        <Form.Control.Feedback type="invalid">
          {errors.confirm_pass}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2" controlId="phone_no">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="text"
          name="phone_no"
          value={formData.phone_no}
          onChange={handleChange}
          isInvalid={!!errors.phone_no}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone_no}
        </Form.Control.Feedback>
      </Form.Group>

      <Button variant="primary" type="submit" disabled={loading.loading_status} >
        {loading.loading_status?'Loading...': 'Register'}
      </Button>
    </Form>
        </div>
        </section>
    </>
  );
};

export default Register;
