
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import TableSkeleton from '../../../components/tables/TableSkeleton';
import { GetScripts, GetSubAdminScripts } from '../../../helpers/BaseFn';
import { AddIcon, EditIcon, TrashBtn, ViewIcon, ItemsPerPagePaginate,InfoIcon } from '../../../helpers/Icons';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { CallGETAPI } from '../../../helpers/API';
import HeaderSubAdmin from '../SubAdminHeader';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../../redux/Slices/LoadingSlice';
import SubAdminDashboardTbl from '../../../components/tables/SubAdminDashboardTbl';

const Dashboard = () => {

	const [loading, setLoading] = useState(false);
	const [scriptList, setScriptList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = ItemsPerPagePaginate;
const dispatch  = useDispatch();
	const LoadPage = async () => {
		setLoading(true);
		dispatch(setReduxLoading(true));
		let result = await GetSubAdminScripts();
		setScriptList(result);
		setLoading(false);
		dispatch(setReduxLoading(false));
	}
	const  handleActivePageChange = (newPage) => {
        setCurrentPage(newPage);
	}

	useEffect(() => { LoadPage() }, [])
    
	const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentScripts = scriptList.slice(startIndex, endIndex);


	return (
		<>

			<main>

				{/* all scripts */}
				<HeaderSubAdmin />
				<section className="all-scripts py-4">

					{/* top heading */}
					<div className="top-heading bg-light-gray py-4">
						<div className="container d-flex justify-content-between align-items-center">
							<h4 className='text-gray-heading'>All Scripts</h4>
							{/* <div> <Link className='btn text-primary p-0' to={"/sub-admin/agent/add"} >+ Add Agent</Link> </div> */}
							<div> <Link className='btn text-primary p-0' to={"/sub-admin/agent/list"} >+ View Agents</Link> </div>
						</div>
					</div>

					{/* table */}
					<div className="container">
					<SubAdminDashboardTbl
                  data={scriptList}
				  is_admin={false}
                />
						{/* <table className='w-100 mt-5'>
							<thead>
								<tr>
									<th scope='col' width="30%" className='text-gray-light py-3'>Script Title</th>
									<th scope='col' width="30%" className='text-gray-light text-center py-3'>Created Date</th>
									<th scope='col' width="30%" className='text-gray-light text-center py-3'>Action</th>
								</tr>
							</thead>

							{loading ? <TableSkeleton /> : ""}
							{scriptList?.length === 0 && !loading ?

								<tbody>
									<tr  >
										<td colSpan={3} className='alert alert-warning' >No Data Found</td>
									</tr>
								</tbody>
								: ""}


							<tbody>
								{scriptList?.length > 0 && !loading ? currentScripts.map((item, index) => (
									<tr key={index}>
										<td className='text-heading py-3'>{item?.script_title}</td>
										<td className='text-gray-light text-center py-3'>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
										<td className='text-primary text-center py-3'>
											<Link to={"/sub-admin/script-details/" + item?._id} 
											
											className='action-icon link text-primary ' title="View Script Details"
											
											 style={{ width: '30px', display: 'inline-block' }}>
												
												<ViewIcon />
											</Link>
											&nbsp; &nbsp;
										</td>
									</tr>
								)) : ""}
							</tbody>
						</table>
 {scriptList.length > 5 && (
  <div className="pagination">
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      style={{padding: '5px 10px',border: '1px solid #ccc', backgroundColor: 'white',cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
        transition: 'background-color 0.3s ease-in-out',

      }}> &lt;  </button>
    <div className="page-buttons"
	
      style={{display: 'flex',overflow: 'hidden',transition: 'transform 0.3s ease-in-out',}}>
      {Array.from(
        { length: Math.ceil(scriptList.length / itemsPerPage) },
        (_, index) => {
          if (
            index === currentPage - 1 ||
            index === currentPage ||
            index === currentPage + 1
          ) {
            return (
				<button
				key={index}
				onClick={() => handlePageChange(index + 1)}
				className={currentPage === index + 1 ? 'active' : ''}
				style={{ margin: '0 5px', padding: '5px 10px', border: '1px solid #ccc', backgroundColor: currentPage === index + 1 ? '#007bff' : 'white', 
				  color: currentPage === index + 1 ? 'white' : 'black', cursor: 'pointer',
				  transition: 'background-color 0.3s ease-in-out',
				}}>{index + 1}</button>
			   );
          } else if (index === currentPage + 2) {
            return (
              <div
                key="ellipsis"
                style={{
                  margin: '0 5px',padding: '5px 10px',}} > ...</div>
            );}
          return null;
        })}
    </div>
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === Math.ceil(scriptList.length / itemsPerPage)}
      style={{ padding: '5px 10px', border: '1px solid #ccc', backgroundColor: 'white',cursor:
	  currentPage === Math.ceil(scriptList.length / itemsPerPage)
		? 'not-allowed'
		: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
      }} 
	  > &gt;</button>
  </div>
)} */}

					</div>
				</section>

			</main>
		</>
	)
}

export default Dashboard;
