import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { GetScripts, GetSubAdminByID } from '../../../helpers/BaseFn';
import moment from 'moment';

const SubAdminDetails = ({ subAdminData, show, handleClose }) => {
  const propertiesToDisplay = ["first_name", "last_name", "email", "createdAt",];

  const [loading, setLoading] = useState(false);
  const [Details, setDetails] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const id = subAdminData?._id;

  const LoadPage = async () => {
    if (!id) return "";

    setLoading(true);

    try {
      const result = await GetScripts();
     
      const res2 = await GetSubAdminByID(id);

      setDetails(res2);

      // Handle agents data separately
      const agentsData = res2?.subadmin?.agents || [];
// console.log(agentsData)
      // Process scripts data and set selected options
      const selectedScripts = res2?.subadmin?.data?.scripts || [];
    
      const scriptOptions = result
        .filter((script) => selectedScripts.includes(script._id))
        .map((script) => ({
          label: script.script_title,
          value: script._id,
        }));
        console.log(scriptOptions)
      setSelectedOptions(scriptOptions);

      setLoading(false);
    } catch (error) {
      console.error("Error loading data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    LoadPage();
  }, [id,show]);

  const formatCreatedAt = (date) => {
    return moment(date).format("YYYY-MM-DD, hh:mm A"); // Customize the format as needed
  };

  const tableRows = propertiesToDisplay.map((propertyName) => (
    <tr key={propertyName}>
      <td>{propertyName === "createdAt" ? "Created At" : propertyName.replace(/_/g, ' ')}</td>
      <td style={propertyName === "email" ? { wordWrap: 'break-word', maxWidth: '200px' } : {}}>
      {propertyName === "createdAt" ? formatCreatedAt(subAdminData[propertyName]) : subAdminData[propertyName]}
    </td>
    </tr>
  ));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Subadmin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '300px' }}>
          {loading ? (
            <div className='form-loader' style={{ minHeight: '200px' }}>
              <div class="uploading-animation form-loader" id="uploading-animation">
              </div>
            </div>
          ) : (
            <>
              <table className='table table-bordered'>
              <tbody>
                  {tableRows}
                  <tr>
                    <td>Assign Script</td>
                    <td>
                      {selectedOptions.map((option, index) => (
                        <div key={index} className='d-flex align-items-center'>
                          <span className='text-bold mr-2'>{option?.label}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Display Agents information in a table */}

              <Modal.Title className="modal-title" style={{ height: '3rem' }}>Agent Details</Modal.Title>
<table className='table table-bordered'>
  <thead>
    <tr>
    <th>Name</th>
      <th >Email</th>
      <th>Created At</th>
    </tr>
  </thead>
  <tbody>
    {Details?.subadmin?.agents && Details.subadmin.agents.length > 0 ? (
      Details.subadmin.agents.map((agent, index) => (
        <tr key={index}>
          <td style={{ wordWrap: 'break-word', maxWidth: '180px' }}>{agent.agentName}</td>
          <td style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{agent.agentEmail}</td>

          <td>{moment(agent.createdAt).format("YYYY-MM-DD, hh:mm A")}</td> {/* Fetch and format Created At */}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="3"  style={{ textAlign: 'center' }}>No agents available</td>
      </tr>
    )}
  </tbody>
</table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubAdminDetails;
