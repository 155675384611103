import React from 'react';
import { useEffect,useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CallPOSTAPI } from '../../helpers/API';
import { toast } from 'react-toastify';
import 'bootstrap-icons/font/bootstrap-icons.css';
const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [validationError, setValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const url_token = searchParams.get('token') || '';

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    setValidationError('');

    if (newPassword !== confirmNewPassword) {
      setValidationError("Passwords do not match?");
    } else if (newPassword.length < 8) {
      setValidationError("Password must be at least 8 characters long?");
    } else if (!/[a-z]/.test(newPassword) || !/[A-Z]/.test(newPassword)) {
      setValidationError("Password must include at least one lowercase and one uppercase letter?");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
      setValidationError("Password must include at least one special character?");
    } else {
      // Here you can implement the logic to update the password
      console.log('Updating password');
      const sendObj = {
        password: newPassword,
      };
      setLoading(true);
      const res = await CallPOSTAPI('api_auth/resetpassword?token=' + url_token, sendObj);
      if (res.data.status === true) {
        toast.success(res.data.message);
        navigate('/');
      } else {
        toast.error(res.data.message);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Forgot Password | Callflow';
  }, []);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  return (
    <>
      {/* <section className="all-scripts py-4"> */}
        <div className="container update-container">
          <div className="navbar-brand text-center">
            <img
              src={"/assets/images/logo.png"}
              alt='logo'
              width={180}
              // height={50}
            />
            <h4 className='text-gray-heading'>{'Update Forgot Password'}</h4>
          </div>
          <div className="main-form p-0 text-left" style={{ maxWidth: '100%', marginTop: "2rem", minHeight: "40vh"}} >
              <Form onSubmit={handleSubmit}>
                <Form.Group className='mt-2'  controlId="newPassword">
                  <Form.Label>
                    New Password <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                  </Form.Label>
                  <div className="input-container">
                    <Form.Control
                      type={showNewPassword ? 'text' : 'password'}
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      required
                      style={{borderRadius: '0'}}
                    />
                    
                    <span
                      className={`password-toggle-icons ${showNewPassword ? 'show' : ''}`}
                      onClick={toggleNewPasswordVisibility}
                    >
                      <i className={showNewPassword ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                    </span>
                  </div>
                  {validationError && <Form.Text className="text-danger">{validationError}</Form.Text>}
                </Form.Group>

                <Form.Group className="mt-5" controlId="confirmNewPassword">
                  <Form.Label>
                    Confirm Password <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                  </Form.Label>
                  <div className="passwords-input">
                    <Form.Control
                      type={showConfirmNewPassword ? 'text' : 'password'}
                      placeholder="Confirm new password"
                      value={confirmNewPassword}
                      onChange={handleConfirmNewPasswordChange}
                      required
                    />
                    <span
                      className={`passwords-toggle-icon ${showConfirmNewPassword ? 'show' : ''}`}
                      onClick={toggleConfirmNewPasswordVisibility}
                    >
                      <i className={showConfirmNewPassword ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                    </span>
                  </div>
                  {validationError && <Form.Text className="text-danger">{validationError}</Form.Text>}
                </Form.Group>

                <div className="mt-3" >
                  <Button variant="primary" type="submit" style={{width: "100%", justifyContent: "center", height:"40px" ,marginTop:"2rem"}} disabled={loading}>
                    {loading ? 'Loading...' : 'Update Password'}
                  </Button>
                </div>
              </Form>
            
          </div>
        </div>
      {/* </section> */}
    </>
  );
}

export default UpdatePassword;
