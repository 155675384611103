import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './styles/app.scss';
import 'reactflow/dist/style.css';
import 'react-tree-graph/dist/style.css';
import './App.css';

import React from 'react';
import { Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';

import Login from './pages/Login';
import ScriptDetails from './pages/script-details/ScriptDetails';
import CreateVerbiage from './pages/create-verbiage/CreateVerbiage';
import Dashboard from './pages/dashboard';
import CreateScript from './pages/CreateScript';
import AgentDashboard from './pages/agent/AgentDashboard';
import AgentScriptDetails from './pages/agent/script-details/AgentScriptDetails';
import TreeViewComp from './components/TreeViewComp/TreeViewComp';
import ScriptDetailsTree from './pages/script-details/ScriptDetailsTree';
import ProtectedRouter from './Routers/ProtectedRouter';
import UnprotectedRouter from './Routers/UnprotectedRouter';
import SubAdminRoutes from './pages/SubAdmin/SubAdminRoutes';
import SubAdminForm from './pages/Admin/SubAdmin/SubAdminForm';
import SubAdminList from './pages/Admin/SubAdmin/SubAdminList';
import EditSubAdminForm from './pages/Admin/SubAdmin/EditSubAdminForm';
import ForgotPassword from './pages/Auth/ForgotPassword';
import UpdatePassword from './pages/Auth/UpdatePassword';
import Register from './pages/register';
import { useSelector } from 'react-redux';

function App() {
  const loading  = useSelector(state=>state?.loadingReducer?.loading);
  return (
    <>
      {loading ? <div className='loading-section-110'><span className='loader-110' >
      </span></div>:''}
      <Routes >
        <Route path='/' element={<Login />} />
        <Route path='/register' element={<Register />} />

        {/* Admin Routes */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/script-details/:script_id' element={<ScriptDetails />} />
        <Route path='/script-details/tree-view/:script_id' element={<ScriptDetailsTree />} />
        <Route path='/create-verbiage/:script_id' element={<CreateVerbiage />} />
        <Route path='/create-scripts' element={<CreateScript />} />
        <Route path='/add/sub-admin' element={<SubAdminForm />} />
        <Route path='/all/sub-admin' element={<SubAdminList />} />
        <Route path='/edit/sub-admin/:id' element={<EditSubAdminForm />} />
        <Route path='/resetpassword' element={<UpdatePassword />} />
        {/* forgot-password */}

        {/* Agent Routes */}
        <Route path='/agent/dashboard' element={<AgentDashboard />} />
        <Route path='/agent/script-details/:script_id' element={<AgentScriptDetails />} />
        <Route path='/demo' element={<TreeViewComp />} />
        

        {/* SubAdmin Routes */}
        <Route path='/sub-admin/*' element={<SubAdminRoutes />} />

      </Routes>
      <ToastContainer />
    </>
  );
}
{/* create-verbiage  */ }

export default App;
