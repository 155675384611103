import * as React from 'react';
// import './toogleSwitch.scss';
import { useState } from 'react';
import { useEffect } from 'react';

export default function ToogleSwitch({switchKeyValue, setSwitchValue, switchKey, switchValue}) {
	const [value, setValue] = useState(false);

	// pass data to parent component
	const passDataChange = (e) => {
		setValue(e.target.checked);
		setSwitchValue({
			key: switchKey,
			value: e.target.checked,
		})
	}

	useEffect(() => {
		setValue(switchKeyValue);
	}, [switchKeyValue])

	return (
		<div id="toogle-section" className="toggle-button-cover d-flex gap-2" style={{alignItems:'center'}}>
			<span>Active: </span>
			<div className="button-cover">
				<div className="button r" id="button-1">
					
					<input type="checkbox" className="checkbox" checked={value || value == 1} onChange={(e) => {passDataChange(e)}} />
					<div className="knobs"></div>
					<div className="layer"></div>
				</div>
			</div>
		</div>
	);
}