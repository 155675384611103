import React from 'react';
import { useSelector } from 'react-redux';
import cx from "classnames";
import { FaSquare, FaCheckSquare, FaMinusSquare, FaArrowRight } from "react-icons/fa";

import Header from './components/TreeMainComp/header';
import TreeContainer from './components/TreeMainComp/treeContainer';
import { resize } from './components/Redux/actions';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { AddIcon, EditIcon } from './helpers/Icons';
import { getVerbiagebyId } from './helpers/BaseFn';
import Skeleton from 'react-loading-skeleton';
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai"
import { useEffect } from 'react';
import { BsChevronDown, BsChevronUp, BsEyeFill } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router';

window.onresize = resize;

const TreeViewData = ({ list, treedata, ids, getVerbiageDetails, handleAddScript }) => {
   const [modalLoading, setModalLoading] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [openNoModal, setOpenNoModal] = useState(false)
   const [modalHead, setModalHead] = useState("")
   const arr = []
   const navigate = useNavigate()
   const [thisId, setThisId] = useState("")
   const [thisScriptId, setThisScriptId] = useState("")
   const [thisParentId, setThisParentId] = useState("")
   const params = useParams()
   const id = params.script_id
   const [verbiagedata, setverbiagedata] = useState({})
   const datanew = {
      name: "",
      children: [treedata],
      expanded: true
   }
   const data = flattenTree(datanew);
   const data2 = flattenTree(datanew);
   data2?.map((item, i) => {
      if (i !== 1) {
         arr.push(item?.id)
      }
   })

 

   useEffect(() => {
      data2?.map((item, i) => {
         if (i !== 1) {
            arr.push(item?.id)
         }
      })
   }, [arr, openModal])


   const handleClick = async (e) => {
      console.log(e)

      setModalHead(e.element.name)
      setThisId(e.element.id)
      setThisParentId(e.element.parent)
      setThisScriptId(e.element.parent)
      for (let i = 0; i < list.length; i++) {
         if (list[i]?.btn_id === e.element.parent) {
            setThisScriptId(list[i]?._id)
            break
         }
      }
      if (e.element.children.length === 0) {
         setOpenNoModal(true)
      }
      else {
         setOpenModal(true)
         let id = ""
         for (let i = 0; i < list.length; i++) {
            if (list[i]?.btn_id === e.element.id) {
               id = list[i]?._id
               setThisId(list[i]?._id)
               break
            }
         }
         setModalLoading(true)
         let result = await getVerbiagebyId(id || e.element.id);
         setverbiagedata(result)
         setModalLoading(false)
      }
   }

   console.log(thisScriptId)

   return (

      <div id="tree-view-section">
         <div className="checkbox wtree tree-view-container">
            <TreeView
               data={data}
               aria-label="Single select"
               multiSelect={false}
               expandedIds={arr}
               onNodeSelect={
                  (e)=> {
                     handleClick(e)
                  }
               }
               // propagateSelectUpwards
               togglableSelect
               defaultExpandedIds={arr}
               nodeAction="check"
               nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  isSelected,
                  isHalfSelected,
                  getNodeProps,
                  level,
                  handleSelect,
                  handleExpand,
               }) => {
                  return (
                     <div
                        {...getNodeProps({ onClick: handleExpand })}
                        style={{ marginLeft: 40 * (level - 1) }}
                     >
                        {isBranch && <ArrowIcon isOpen={isExpanded} />}
                        <BsEyeFill
                           className="checkbox-icon"
                           onClick={(e) => {
                              handleSelect(e);
                              e.stopPropagation();
                           }}
                           variant={
                              isHalfSelected ? "some" : isSelected ? "all" : "none"
                           }
                        />
                        <span className="name">{element.name}</span>
                     </div>
                  );
               }}
            />
         </div>

         <Modal show={openNoModal} onHide={(e) => { setOpenNoModal(false) }}>
            <Modal.Header className='pb-0' closeButton>
               <Modal.Title className='w-100'><div >
                  <h6 className='mb-0'>{modalHead}</h6>
               </div>
               </Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
               <div className="d-flex justify-content-end align-items-center c-pointer" onClick={() =>  navigate(`/create-verbiage/${id}?verbiage_id=${thisScriptId}&btnId=${thisId}`)} >
                  <AiOutlinePlus />{" Add More"}
               </div>
               <h6 className='my-4'>No Verbiage Found</h6>
            </Modal.Body>
         </Modal>
         <Modal show={openModal} onHide={(e) => { setOpenModal(false) }} backdrop="static">
            <Modal.Header className='pb-0' closeButton>
               <Modal.Title className='w-100'><div className="d-flex justify-content-between align-items-center">
                  <h6 className='mb-0'>{modalHead}</h6>
                  <div className='d-flex gap-1' >
                     <button className='btn text-primary pe-0' type="button" onClick={() => getVerbiageDetails(thisId)} style={{ width: '40px' }} > <span style={{ width: '40px', color: "rgb(38, 138, 255)" }}
                        title='Edit Verbiage'
                     >
                        <EditIcon color="#d3dae4" />
                     </span> </button>
                     <button className='btn text-primary  px-0' type="button" onClick={() => handleAddScript(thisId, '', 0)} style={{ width: '28px' }}
                        title='Add verbiage'> <span style={{ width: '28px', color: "rgb(38, 138, 255)" }}>
                           <AddIcon />
                        </span>
                     </button>

                  </div></div>
               </Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
               {
                  modalLoading ? (<>
                     <div className=''>
                        <div className="text-heading text-center py-3 row mb-3">
                           <Skeleton width="300px" height="40px" />
                           <Skeleton width="300px" height="30px" />
                        </div>
                        <div className="row text-gray-light text-center py-3 mb-3">
                           <Skeleton width="300px" height="40px" />
                           <Skeleton width="300px" height="30px" />
                        </div>
                     </div>
                  </>) : (
                     <>
                        <h5>Title:</h5>
                        <h6 className='mb-5'>
                           {verbiagedata?.verbiage_title}
                        </h6>
                        <h5>Description:</h5>
                        <p dangerouslySetInnerHTML={{
                           __html: verbiagedata?.verbiage_description
                        }}></p>
                        <div className="view-button-group mt-4 mb-2">
                           {verbiagedata?.btn_arr?.map((it, i) => (
                              <div className="form-group" key={i} >

                                 <input type="checkbox" className="btn-check" id={"no-objection-btn_" + it?._id}
                                    checked={parseInt(it?.is_created)}
                                    autoComplete="off" readOnly
                                    disabled={it?.is_created == 1}
                                    onClick={() => handleAddScript(verbiagedata?._id, it?._id, parseInt(it?.is_created))}
                                 />

                                 <>
                                    {parseInt(it?.status) === 0 ? <label
                                       className="btn  btn-outline-warning px-3 py-2 "
                                       htmlFor={"no-objection-btn_" + it?._id}>{it?.title}</label> : <label
                                          className="btn  btn-outline-primary px-3 py-2 "
                                          htmlFor={"no-objection-btn_" + it?._id}>{it?.title}</label>}
                                 </>
                              </div>
                           ))}
                        </div>


                     </>
                  )
               }

            </Modal.Body>
         </Modal>
      </div>


   );
}

const ArrowIcon = ({ isOpen, className }) => {
   const baseClass = "arrow";
   const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
   );
   return (
      isOpen ?
         (<>
            <BsChevronUp className={classes} />
         </>) : (<>
            <BsChevronDown className={classes} />
         </>)
   );
};

const CheckBoxIcon = ({ variant, ...rest }) => {
   switch (variant) {
      case "all":
         return <FaCheckSquare {...rest} />;
      case "none":
         return <FaSquare {...rest} />;
      case "some":
         return <FaMinusSquare {...rest} />;
      default:
         return null;
   }
};

export default TreeViewData;
