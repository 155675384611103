import React, { useState } from 'react';
import { CallPOSTAPI } from '../../helpers/API';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { BackIcon } from '../../helpers/Icons';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

function AddScript() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState({
        loading_status: false,
        msg: '',
    });

    const [title, setTitle] = useState('');
    const [isTitleTouched, setIsTitleTouched] = useState(false);
    const [validated, setValidated] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading((prevState) => ({ ...prevState, loading_status: true }));
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            setLoading({ msg: "Please Enter Script Title", loading_status: false });
            e.stopPropagation();
        } else {
            const trimmedTitle = title?.trim();

            if (!trimmedTitle) {
                toast.error("Please Enter Script Title");
            } else {
                const send_data = { script_title: trimmedTitle };
                const res = await CallPOSTAPI('script/script_add', send_data);

                if (res?.data?.status) {
                    toast.success("Script Added Successfully");
                    const id = res?.data?.data?._id;
                    navigate('/create-verbiage/' + id);
                } else {
                    toast.error("Something went wrong. Please try again");
                }
            }

            setLoading((prevState) => ({ ...prevState, loading_status: false }));
        }
        setValidated(true);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        setIsTitleTouched(true);
    };

    const isTitleNotEmpty = title?.trim() !== '';

    return (
        <>
            <section className="all-scripts py-4">
                <div className="top-heading bg-light-gray py-4">
                    <div className="container d-flex justify-content-between align-items-center">
                        <h4 className='text-gray-heading'>
                            <Link to="/dashboard" className='btn btn-link'>
                                <BackIcon />
                            </Link>
                            Add New Script
                        </h4>
                    </div>
                </div>

                <div className='main-form'style={{width:'40%'}}>
                    <Form noValidate validated={validated} onSubmit={submitHandler}>
                        <Form.Group controlId="title" className='mb-3'>
                            <Form.Label>Title <span style = {{color:"red",fontSize:"14px"}}>*</span> </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                value={title}
                                onChange={handleTitleChange}
                                required
                                isInvalid={!isTitleNotEmpty && isTitleTouched}
                                isValid={isTitleNotEmpty}
                            />
                            {isTitleNotEmpty ? (
                                <Form.Control.Feedback></Form.Control.Feedback>
                            ) : (
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <button className='primaryBtn' style={{ float: 'right',width:'120px',borderRadius:'6px' }}>
                          {loading?.loading_status ? "Loading" : "Next"}
                         </button>


                    </Form>
                </div>
            </section>
        </>
    );
}

export default AddScript;
