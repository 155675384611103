import React from 'react'
import { Button, Form, Modal,Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { CallPOSTAPI } from '../../../helpers/API';
import { GetScripts, getUsers } from '../../../helpers/BaseFn';
import { Link, useNavigate } from 'react-router-dom';
import { BackIcon } from '../../../helpers/Icons';
import HeaderSubAdmin from '../SubAdminHeader';
import { useDispatch } from 'react-redux';
function EditAgentModal({show,handleClose,useData,refreashData}) {
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [loading2, setLoading2] = React.useState({
        loading_status: false,
        msg: '',
     });
     const user     = getUsers(localStorage.getItem("callflow_token"))
     const navigate = useNavigate()
     const [details, setDetails] = useState({
        first_name: "",
        email: "",
        last_name: "",
        subAdminId:user?.user_id,
        _id:""
     })
     useEffect(()=>{
        if(useData){
           setDetails({
              first_name: useData.first_name,
              last_name: useData.last_name,
              email:useData.email,
              subAdminId:user?.user_id,
              _id:useData?._id
           })
        }
     },[useData])


      const handleCancel = () => {
      handleClose();
    };

    const handleNameChange = (e) => {
      const value = e.target.value;
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setDetails({
         ...details, [e.target.name]: e.target.value
      })}else{
         return '';
      }
   }
   //   const handleChange = (e) => {
   //      setDetails({
   //         ...details, [e.target.name]: e.target.value
   //      })
   //   }
  
   const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Check if the input value contains only letters and spaces for first_name and last_name
      if ((name === "first_name" || name === "last_name") && (/^[A-Za-z]+( [A-Za-z]*)*$/.test(value) || value === '')) {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
      
      if (name === "email") {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
    };
    
    
    
  
     const submitForm = async (e) => {
        e.preventDefault();
        setUpdateLoading(true);

        const data = {
            firstName: details.first_name,
            lastName: details.last_name,
           email: details.email,
           subAdminId:user?.user_id
        }
        if(!details?._id){
            toast.error("Agent Id is required!");
            return "";
        }
        setLoading2((prevState) => ({ ...prevState, loading_status: true }));
        let res = await CallPOSTAPI('api_auth/update-agents/'+details?._id, data);
      
        setLoading2((prevState) => ({ ...prevState, loading_status: false }));
        if (res?.data?.status) {
        //    setDetails({
        //       first_name: "",
        //       email: "",
        //       last_name: "",
        //    })
            
           toast.success("Agent Updated Successfully");
           handleClose();
           refreashData();
        } else
         {
           toast.error(res?.data?.message);
        }
      
        setUpdateLoading(false);

     }
     

  return (
    <>
   <Modal show={show} onHide={handleClose}  backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>Edit Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loading ? <div className='form-loader' style={{minHeight:'200px'}}>
          <div class="uploading-animation form-loader" id="uploading-animation"  > 
          </div>
         </div> : 
           <Form onSubmit={submitForm} className='col-12 m-auto my-2' > 
           {/* <h4 style={{textAlign: 'center', marginBottom: '5rem'}}>Edit SubAdmin</h4>         */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className='row'>
              <div className='col-6'>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>First Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                    <Form.Control required type="text"
                       name="first_name"
                       value={details?.first_name}
                       // onChange={handleChange}
                       onChange={handleNameChange}
                       placeholder="Enter First Name" 
                       style={{borderRadius: '0'}} />
                 </Form.Group>
                 </div>
                 <div className='col-6'>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Last Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                    <Form.Control required type="text"
                       name="last_name"
                       value={details?.last_name}
                       onChange={handleNameChange}
                       placeholder="Enter Last Name"
                       style={{borderRadius: '0'}} />
                 </Form.Group>
                 </div>
                 </div>
                 <Form.Label>Email address  <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                 <Form.Control required type="email"
                    name="email"
                    value={details?.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                    style={{borderRadius: '0'}} />
              </Form.Group>

              <div className="d-flex justify-content-end">
              <Button disabled={loading2.loading_status} style={{ width: "150px", marginRight: "10px" }} variant="secondary"onClick={handleCancel}> Cancel </Button>
                 <Button disabled={updateLoading} style={{ width: "150px" }} variant="primary" type="submit">
                    {updateLoading ? (<Spinner size="sm" animation="border" />) : ("Update")}
                 </Button>
              </div>
           </Form>
}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditAgentModal