import { toast } from 'react-toastify';
import React, { useState } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { CallPOSTAPI } from '../../helpers/API';
import { Navigate } from 'react-router';

const ForgotPassword = ({setisForgotPWd}) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    
    const navigate = useState();
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    
  
    // const handleCancel = () => {
    //   setEmail('');
    // };

    const handleCancel = () => {
      // navigate('/Login'); // Redirect to the login page
      setisForgotPWd(false)
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      // Here you can implement the logic to send a password reset email
      console.log('Sending password reset email to:', email);
      const sendObj = {
        email: email,
      };
      setLoading(true);
      const res = await CallPOSTAPI('api_auth/forgetpassword', sendObj);
      if (res.data.message) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
        setEmail('');
      }
      setLoading(false);
    };
  
    return (
        <>
          {/* <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}> */}
              <div className="container" style={{marginTop: "10rem !important"}}>

                {/* <div className="navbar-brand text-center login-logo">
                  <img
                    src={"/assets/images/logo.png"}
                    alt='logo'
                    width={180}
                    // height={50}
                  />
                  <h4 className='text-gray-heading'>Forgot Password</h4>
                </div> */}
                  <div className="main-form p-0 mt-5 text-left " style={{ maxWidth: '100%', minHeight: "25vh  " }} >
                  <Form onSubmit={handleSubmit} >
                    <Form.Group controlId="email">
                      <Form.Label>
                        Email address <span style={{ color: 'red',fontSize: '14px'  }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        style={{borderRadius: '0'}}
                      />
                    </Form.Group>
                    <div className="justify-content-between mt-2" style={{textAlign: 'right'}}>
                          <Button
                            variant="link"
                            className="mt-4"
                            onClick={handleCancel}
                            style={{
                              textDecoration: 'none',
                              color: '#0d6efd',
                              padding: 6,
                              border: '1px solid #0d6efd',
                              marginRight: '1rem',
                              width: "100px"
                              // marginLeft:"440px"
                            }}
                          >
                          <span style={{ color: '#0D6EF3' }}>Cancel</span>
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          className="mt-4"
                          style={{width: "100px"}}                        
                        >
                          {loading ? 'Loading...' : 'Submit'}
                        </Button>
  </div>


                  </Form>
                  </div>
                </div>
              {/* </Col>
            </Row>
          </Container> */}
        </>
      );
    };
    
    export default ForgotPassword;