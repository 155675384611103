import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { ActivateIcon, AddIcon, EditIcon, TrashBtn, ViewIcon } from '../../helpers/Icons';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {BsTree} from "react-icons/bs"
// setEditVerbiageId
/** */
function ScriptDetailsDrop({ item, handleDelete, setOpenEditModal, handleShow, is_deactivated=false,handleActivate}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const [openEditModal,setOpenEditModal] = useState(false);
    // const [editVerbageId,setEditVerbiageId] = useState("");
    const openEditModalFn = (id) => {
        setOpenEditModal(true)
        // setEditVerbiageId(id);
        handleShow(id)
    }

    const viewDetail = (id) =>{
        console.log(id)
    }
    return (
        <>
            <div className='d-flex justify-content-right action-section' >
                {/* <span className="action-icon">     */}
                <Link to={`/script-details/tree-view/${item?._id}`} className='action-icon btn link text-primary' title="View Script Details" >
                    <BsTree />
                </Link>
                <Link to={`/script-details/${item?._id}`} className='action-icon btn link text-primary' title="View Script Details" >
                    <ViewIcon />
                </Link>
                {/* </span> */}
                <span type="button" onClick={() => openEditModalFn(item?._id)} className='action-icon  btn link text-primary' title="Edit Script" >
                    <EditIcon />
                </span>
                {/* <span className="action-icon"><EditIcon/></span> */}
                {/* <span className="action-icon"><AddIcon/></span> */}

                <Link to={"/create-verbiage/" + item?._id} className='action-icon btn link text-primary ' title="Add Verbiage" >
                    <span className='cursor-pointer' ><AddIcon /></span>
                </Link>


        {is_deactivated ? <span style={{ width: '50px' }} className='btn text-success action-icon ' onClick={() => confirmAlert({
														title: 'Active Script',
														message: 'Are you sure to do this.',
														buttons: [
															{
																label: 'Yes',
																onClick: () => handleActivate(item?._id)
															},
															{
																label: 'No',
																onClick: () => { }
															}
														]
													})}
														title={'Active Script'}
													>
														<span className='cursor-pointer '  ><ActivateIcon /></span>
													</span> : 
                <span style={{ width: '25px' }} className="action-icon" type='button' onClick={() => confirmAlert({
                    title: 'Deactivate Script',
                    message: 'Are you sure to do this.',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => handleDelete(item?._id)
                        },
                        {
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                })}
                    title="Deactivate Script" >
                    <TrashBtn />
                </span>
}
            </div>

            {/* <Button
                id={"basic-button-"+item?._id}
                aria-controls={open ? 'basic-menu-'+item?._id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </Button>
            <Menu
                id={"basic-menu-"+item?._id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button-'+item?._id,
                }}
            >
                <MenuItem >
                    <Link to={"/script-details/"+item?._id}  className='btn link text-primary'>
                        <span className='cursor-pointer' >View</span>
                    </Link>
                </MenuItem>
                <MenuItem >
                    <Link to={"/create-verbiage/"+item?._id} className='btn link text-primary ' >
                        <span className='cursor-pointer' >Add Verbiage</span>
                    </Link>
                </MenuItem>

                
                <MenuItem >
                    <Button type="button" onClick={()=>openEditModalFn(item?._id)}  className='btn link text-primary'>
                        <span className='cursor-pointer' >Edit</span>
                    </Button>
                </MenuItem>

                <MenuItem >
                <button className='btn text-danger ' type='button' onClick={()=>	confirmAlert({
                                                                                    title: 'Delete Script',
                                                                                    message: 'Are you sure to do this.',
                                                                                    buttons: [
                                                                                    {
                                                                                        label: 'Yes',
                                                                                        onClick: () => handleDelete(item?._id)
                                                                                    },
                                                                                    {
                                                                                        label: 'No',
                                                                                        onClick: () => {}
                                                                                    }
                                                                                    ]
                                                                                })} 
                                                                                
                                                                                >
                <span className='cursor-pointer '  >Delete</span>
                </button>
                </MenuItem>
            </Menu> */}
        </>
    )
}

export default ScriptDetailsDrop