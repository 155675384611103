// import React, { useEffect, useRef, useState } from "react";

// export default function MyCkeditor({ onChange, editorLoaded, name, value,checkRef }) {
//     const editorRef = useRef();
//     const { CKEditor, ClassicEditor } = editorRef.current || {};
//     const [editor, setEditor] = useState(null);

//     const onBeforeLoad = (e) => {
//         setEditor(e.editor);
//     }
//     useEffect(() => {
//         editorRef.current = {
//          CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, 
//          ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
//         };
//     }, []);

//     useEffect(() => {
//         if (editor) {
//             editor.setData(value);
//         }
//     }, [value]);
//     useEffect(()=>{
//         if(editorRef.current){
//         editorRef.current.focus();
//         }
//     },[checkRef?.current])
//     return (
//         <>
//             {editorLoaded ? (
//                 <CKEditor
//                     type=""
//                     name={name}
//                     editor={ClassicEditor}
//                     config={{
//                         placeholder: 'Enter your Verbiage description...',// Set the placeholder text
//                       }}
//                     onLoaded={onBeforeLoad}
//                     data={value}
//                     onChange={(event, editor) => {
//                         const data = editor.getData();
//                         onChange(data);
//                     }}
//                 />
//             ) : (
//                 <div>Editor loading</div>
//             )}
//         </>
//     )
// }

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React,{useState,useRef,useEffect} from 'react';

function MyCkeditor({ onChange, editorLoaded, name, value,checkRef }) {
 
    const editorRef = useRef();
    const editorRefDiv = useRef(null);
    useEffect(()=>{
        if(editorRef.current && checkRef?.current){
        // console.log({editorRef:editorRef.current, checkRef:checkRef?.current,editing:editorRef.editing})
        // console.log( editorRef.editing.view.document.isFocused );

            // editorRef?.current?.editor?.focus();
            // editorRef?.editing?.view?.focus();
            // editorRefDiv.current.focus();
            console.log('hello2');
            document.querySelectorAll('#TEXT_FOCUS')[0].scrollIntoView();

        }
    },[checkRef?.current])
   return (
    <>
    <div className='' ref={editorRefDiv} >
                       <CKEditor
                     type=""
                     name={name}
                     editor={ClassicEditor}
                     config={{
                         placeholder: 'Enter your Verbiage description...',
                       }}
                    //  onLoaded={onBeforeLoad}
                     data={value}
                     onChange={(event, editor) => {
                         const data = editor.getData();
                         onChange(data);
                     }}
                     ref={editorRef}
                 />
                 </div>
    </>
  )
}

export default MyCkeditor
