import { createSlice } from '@reduxjs/toolkit'
const initialState  = {
    loading:false
}; 
export const loadingSlice = createSlice({
    name:"loading",
    initialState,
    reducers:{
        setReduxLoading:(state,action)=>{
            state.loading = action.payload
        }
    }
})

export const { setReduxLoading } = loadingSlice.actions
export default loadingSlice.reducer;