import React from 'react';
// import AddNewScript from '../components/AddNewScript'
import AddScript from '../components/ScriptForm/AddScript';
import Header from '../components/Header';

function CreateScript() {
  return (
    <>
      <Header />
      <AddScript />
    </>
  )
}

export default CreateScript