import React, { useEffect, useState } from 'react';
import { CallPOSTAPI } from '../../helpers/API';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Button} from 'react-bootstrap';
function EditScript({ handleClose, scriptDetails,editLoading,refeshScripts }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    loading_status: false,
    msg: '',
  });

  const handleCancel = () => {
    handleClose();
  };

  const [title, setTitle] = useState(scriptDetails?.script_title);
  useEffect(()=>{
    setTitle(scriptDetails?.script_title);
  },[scriptDetails?.script_title])
  const [isTitleTouched, setIsTitleTouched] = useState(false);
  const [validated, setValidated] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading((prevState) => ({ ...prevState, loading_status: true }));
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setLoading({ msg: "Please Enter Script Title", loading_status: false });
      e.stopPropagation();
    } else {
      // console.log({title});return "";
      const trimmedTitle = title?.trim();

      if (!trimmedTitle) {
        toast.error("Please Enter Script Title");
      } else {
        const send_data = { script_title: trimmedTitle };
        const res = await CallPOSTAPI('script/edit_script?script_id=' + scriptDetails?._id, send_data);

        if (res?.data?.status) {
          toast.success("Script updated Successfully");
          const id = res?.data?.data?._id;
          handleClose();
          refeshScripts();
        } else {
          toast.error("Something went wrong. Please try again");
        }
      }

      setLoading((prevState) => ({ ...prevState, loading_status: false }));
    }
    setValidated(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setIsTitleTouched(true);
  };

  const isTitleNotEmpty = title?.trim() !== '';

  return (
    <>
      <section className="all-scripts py-4">
        <div className='' >
          {editLoading ? 'Loading...' :
          <Form noValidate validated={validated} onSubmit={submitHandler} id="edit-script-title" >
            <Form.Group controlId="title" className='mb-3'>
              <Form.Label>Title <span style={{ color: "red", fontSize: "14px" }}>*</span> </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={handleTitleChange}
                required
                isInvalid={!isTitleNotEmpty && isTitleTouched}
                isValid={isTitleNotEmpty}
                style={{borderRadius: "0"}}
              />
              {isTitleNotEmpty ? (
                <Form.Control.Feedback></Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="invalid">
                  Please enter a title.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className='mb-3' style={{textAlign: "right",position:'relative',top:'20px'}}>   
            <Button disabled={loading?.loading_status} style={{ width: "120px", marginRight: "10px",borderRadius:'6px',padding:'5px' }} variant="secondary"onClick={handleCancel}> Cancel</Button>
              <button className='primaryBtn' style={{width:'120px',borderRadius:'6px'}} disabled={loading?.loading_status} >{loading?.loading_status?"Loading":"Update"}</button>
            </Form.Group>
          </Form>}
        </div>
      </section>
    </>
  );
}

export default EditScript;
