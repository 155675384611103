import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { CallPOSTAPI } from '../../../helpers/API';
import { GetScripts, getUsers } from '../../../helpers/BaseFn';
import { Link, useNavigate } from 'react-router-dom';
import { BackIcon } from '../../../helpers/Icons';
import HeaderSubAdmin from '../SubAdminHeader';

function AddAgentModal({show,handleClose,useData,refreashData}) {
   const [isEmailValid, setIsEmailValid] = useState(true);
   const [isBlank, setIsBlankValid] = useState(true);
   const [isBlank1, setIsBlankValid1] = useState(true);
    const [loading2, setLoading2] = React.useState({
        loading_status: false,
        msg: '',
     });
     const user     = getUsers(localStorage.getItem("callflow_token"))
     const navigate = useNavigate()
     const [details, setDetails] = useState({
        first_name: "",
        email: "",
        last_name: "",
        subAdminId:user?.user_id,
        _id:""
     })
     
     const resetForm = () => {
      setDetails({
        first_name: '',
        email: '',
        last_name: '',
      });
   }
      
     const resetValidation = () => {
      setIsEmailValid(true);
      setIsBlankValid(true);
      setIsBlankValid1(true);
      
    };
   
    useEffect(() => {
      if (show) {
        resetValidation();
        // Additional logic if needed
      }
    }, [show]);
    

     const handleCancel = () => {
      resetForm();
      handleClose();
    };   

    const handleBlank = (event) => {
      const { name, value } = event.target;
      const isBlank  = /\S+/.test(value)
      setIsBlankValid(isBlank)
      // Show error message if email is invalid
     if (!isBlank1) {
      }  
    }; 
    const handleBlank1 = (event) => {
      const { name, value } = event.target;
      const isBlank1 = /\S+/.test(value)
      setIsBlankValid1(isBlank1)

      if (!isBlank) {
      } 
    };
    
    const isFormValid = () => {
      return isEmailValid;
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!isFormValid()) {
        toast.error("Please fix the errors before submitting.");
        return;
      }
  
      // Rest of the submitForm logic
    };

    const handleEmailBlur = (event) => {
      const { name, value } = event.target;
      // Email validation using a regular expression
      const isValidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value);
      setIsEmailValid(isValidEmail);
  
      if (!isValidEmail) {
      }
    };

    //  useEffect(()=>{
    //     console.log("Hello")
    //     console.log({useData,details});
    //     if(useData){
    //        setDetails({
    //           first_name: useData.first_name,
    //           last_name: useData.last_name,
    //           email:useData.email,
    //           subAdminId:user?.user_id,
    //           _id:useData?._id
    //        })
    //     }
    //  },[useData])

   //   const handleChange = (e) => {
   //      setDetails({
   //         ...details, [e.target.name]: e.target.value
   //      })
   //   }
  
   const handleChange = (e) => {
      const { name, value } = e.target;
  
  
      // Check if the input value contains only letters and spaces for first_name and last_name
      if ((name === "first_name" || name === "last_name") && (/^[A-Za-z]+( [A-Za-z]*)*$/.test(value) || value === '')) {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
      
      if (name === "email") {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
    };
    
    
    
  
     const submitForm2 = async (e) => {
        e.preventDefault();
        
        const data = {
            firstName: details.first_name,
            lastName: details.last_name,
           email: details.email,
           subAdminId:user?.user_id
        }
        console.log({details,data})
        if(!details?._id){
            toast.error("Agent Id is required!");
            return "";
        }
  
        setLoading2((prevState) => ({ ...prevState, loading_status: true }));
        let res = await CallPOSTAPI('api_auth/update-agents/', data);
      
        setLoading2((prevState) => ({ ...prevState, loading_status: false }));
        console.log(res?.data?.status)
        if (res?.data?.status) {
           toast.success("Agent Updated Successfully");
           handleClose();
           refreashData();
        } else
         {
        //    console.log(res?.data?.status)
           toast.error(res?.data?.message);
        }
  
     }


     const submitForm = async (e) => {
        e.preventDefault();
        
        const data = {
           first_name: details.first_name,
           last_name: details.last_name,
           email: details.email,
           subAdminId:user?.user_id
        }
        console.log(data)
  
        setLoading2((prevState) => ({ ...prevState, loading_status: true }));
        let res = await CallPOSTAPI('api_auth/agents', data);
      
        setLoading2((prevState) => ({ ...prevState, loading_status: false }));
        console.log(res?.data?.status)
        if (res?.data?.status) {
           setDetails({
              first_name: "",
              email: "",
              last_name: "",
           })
           handleClose();
           refreashData();
           toast.success("Agent Added Successfully");
           //   console.log(res?.data?.data?._id);    
           //   let id = res?.data?.data?._id;
           //   navigate('/create-verbiage/'+id);
        } else
         {
           console.log(res?.data?.status)
           toast.error("Email already exists!");
        }
  
     }
  

  return (
    <>
   <Modal show={show} onHide={handleClose}  backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>Add Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>


            
        {/* <Form onSubmit={submitForm} className='col-12 m-auto my-2'>
            <Form.Group className="mb-3" controlId="formBasicEmail">
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>First Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="first_name"
                     value={details?.first_name}
                     onChange={handleChange}
                     placeholder="Enter First Name" />
               </Form.Group>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Last Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="last_name"
                     value={details?.last_name}
                     onChange={handleChange}
                     placeholder="Enter Last Name" />
               </Form.Group>

               <Form.Group className="mb-3" controlId="formBasicPassword">
               <Form.Label>Email address  <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
               <Form.Control required type="email"
                  name="email"
                  value={details?.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  style={{borderRadius: '0'}} />
            </Form.Group>

            </Form.Group>
            <div className="d-flex justify-content-end">
               <Button disabled={loading2.loading_status} style={{ width: "150px" }} variant="primary" type="submit">
                  Submit
               </Button>
            </div>
         </Form> */}


<Form onSubmit={submitForm} className='col-12 m-auto my-2' style={{maxWidth: '100%'}}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className='row'>
            <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>First Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="first_name"
                     value={details?.first_name}
                     onChange={handleChange}
                     onBlur={handleBlank}
                     placeholder="Enter First Name"
                     style={{borderRadius: '0'}}
                     className={isBlank ? '' : 'is-invalid'}/>
                     {!isBlank && <div className="invalid-feedback">First name is required</div>}
                       
                     
               </Form.Group>
               </div>
               <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Last Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="last_name"
                     value={details?.last_name}
                     onChange={handleChange}
                     onBlur={handleBlank1}
                     placeholder="Enter Last Name"
                     style={{borderRadius: '0'}}
                     className={isBlank1 ? '' : 'is-invalid'}/>
                     {!isBlank1 && <div className="invalid-feedback">First name is required</div>}
                      
                     
               </Form.Group>
               </div>
               </div>
               <Form.Label>Email address <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
               <Form.Control required type="email"
                  name="email"
                  value={details?.email}
                  onChange={handleChange}
                  onBlur={handleEmailBlur} 
                  placeholder="Enter Email"
                  style={{borderRadius: '0'}}
                  className={isEmailValid ? '' : 'is-invalid'}
                  />
                  {!isEmailValid && <div className="invalid-feedback">
                      {details?.email!='' ? "Invalid email format":"Email is required"}
                    </div>}
                   
            </Form.Group>
            <div className="d-flex justify-content-end">
            <Button disabled={loading2.loading_status} style={{ width: "150px", marginRight: "10px" }} variant="secondary"onClick={handleCancel}>cancel</Button>
               <Button disabled={loading2.loading_status} style={{ width: "150px" }} variant="primary" type="submit">
                  Submit
               </Button>
            </div>
         </Form>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddAgentModal