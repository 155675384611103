import React from 'react'
import SubAdminForm from './SubAdminForm'
import { Modal } from 'react-bootstrap'

function AddSubAdminModal({show,handleClose,refreashList}) {
  return (
    <>
    
    <Modal show={show} onHide={handleClose} className="modal-lg" backdrop="static">
            <Modal.Header closeButton>
            <Modal.Title className="modal-title"> Add SubAdmin</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight:'300px'}} >
            <SubAdminForm handleClose={handleClose} refreashList={refreashList} />

            </Modal.Body>
    </Modal>
    
    </>
  )
}

export default AddSubAdminModal