'use client';
import React from 'react'
import { useCallback } from 'react';
// import { TreeData } from './data';
import { ConvertToHierarchy } from '../../helpers/BaseFn';
// import Tree from 'react-tree-graph';
import { Tree } from 'react-tree-graph';

const TreeViewComp  = ({TreeData})=>{
    // const data      = CreateTree(TreeData);
    const data = ConvertToHierarchy(TreeData);

    console.log({data});

    // let data = {
    //   name: 'Parent',
    //   id: 0,
    //   children: [{
    //     name: 'Child One',
    //     id: "1",
    //     children: [{
    //       id: "2",
    //       name: 'Child One Child',
    //     }]
    //   }, {
    //     id: "3",
    //     name: 'Child Two'
    //   }, {
    //     id: "4",
    //     name: 'Child Two'
    //   }]
    // };

  return (
    <>
    
    <h1  className=' text-center ' style={{fontSize:'24px'}}>Tree View of Verbiage</h1>
    <div className='' style={{padding:'2px 10px '}} >
        <div style={{ width: '100%', height: '100vh',border:'2px solid #ccc',padding:'0px 10px' }} >
        <Tree
            data={data}
            height={400}
            width={400}
          />
        </div>
    </div>
    </>
  )
}

export default TreeViewComp