import { CallGETAPI, CallPOSTAPI } from "./API"

import { TreeData } from "../components/TreeViewComp/data";

export const getVerbiage = async () => {
  let result = await CallGETAPI('script/get_verbiage_data');
  if (result?.data?.Data) {
    return result?.data?.Data;
  }
  return [];
}


export const getUsers = (token) => {
  // if token not available
  if (!token) return false;

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export const GetScripts = async () => {
  let res = await CallGETAPI('script/get_script_data');
  return res?.data?.data || [];
}

export const GetDeletedScript = async () => {
  let res = await CallGETAPI('script/fetch_delete_script');
  return res?.data?.data || [];
}

export const getVerbiagebyId = async (id) => {
  let res = await CallGETAPI('script/get_verbiage_data_id?id=' + id);
  return res?.data?.data || {};
}

export const GetVerbiageByButton = async (btnid, script_id) => {
  let res = await CallGETAPI(`script/fetch_verbiage_btnId?btn_id=${btnid}&script_id=${script_id}`);
  return res?.data?.data || [];
}

export const GetScriptDetail = async (id) => {
  let res = await CallGETAPI('script/get_script_data_id?id=' + id);
  return res?.data?.data || {};
}

// subadmin paths

export const GetAllSubAdmins = async () => {
  let res = await CallGETAPI('api_auth/get_subadmin');
  return res?.data?.data || {};
}
export const BlockSubAdmin = async (id) => {
  let res = await CallPOSTAPI('api_auth/block_agents/'+id);
  return res?.data || {};
}
export const UnBlockSubAdmin = async (id) => {
  let res = await CallPOSTAPI('api_auth/unblock_agents/'+id);
  return res?.data?.data || {};
}
export const UpdateSubAdmin = async (id,data) => {
  let res = await CallPOSTAPI('api_auth/update_subadmin/'+id,data);
  return res?.data?.data || {};
}
export const GetSubAdminByID = async (id) => {
  let res = await CallGETAPI('api_auth/get_subadmin_id/'+id);
  return res?.data?.data || {};
}
export const GetSubAdminScripts = async () => {
  let res = await CallGETAPI('api_auth/get_subadmin_scripts');
  return res?.data?.data || [];
}


// agent paths 


export const GetAllAgents = async () => {
  let res = await CallGETAPI('api_auth/agents-fetch');
  return res?.data?.data || {};
}

export const BlockAgent = async (id) => {
  let res = await CallPOSTAPI('api_auth/block_agents/'+id);
  return res?.data || {};
}
export const UnBlockAgent = async (id) => {
  let res = await CallPOSTAPI('api_auth/unblock_agents/'+id);
  return res?.data?.data || {};
}


export const CreateTree = (data) => {
  const element = [];
  const connection = [];
  for (let index = 0; index < data.length; index++) {
    const node = data[index];

    if (index === 0) {
      let obj1 = { title: node.verbiage_title, id: node._id, data: { label: node.verbiage_title } }
      element.push(obj1);
    }

    if (data[index].btn_arr.length > 0) {
      for (let index1 = 0; index1 < data[index].btn_arr.length; index1++) {
        const node2 = data[index].btn_arr[index1];
        let obj2 = { title: node2.title, id: node2._id, data: { label: node2.title } }
        let connectionObj = {};
        if (index === 0) {
          connectionObj = { id: node2._id, source: node._id, target: node2._id };//;{ title: node2.title,id:  node2._id, data: { label: node2.title } }
        } else {
          connectionObj = { id: node2._id, source: node.btn_id, target: node2._id };
        }
        element.push(obj2);
        connection.push(connectionObj);
      }
    }
  }
  return { nodes: element, connection: connection };
}



export const ConvertToHierarchy = (inputData) => {
  let harr = [];
  for (let i1 = 0; i1 < inputData.length; i1++) {
    const element1 = inputData[i1];
    const obj = { name: element1.verbiage_title, verbiage_id: element1?._id, id: element1._id, parent_id: '', children: [],is_disabled: 0 };
    harr.push(obj);
    for (let i2 = 0; i2 < element1.btn_arr.length; i2++) {
      const element2 = element1.btn_arr[i2];
      let obj2 = { name: element2.title, verbiage_id: element1?._id, id: element2._id, parent_id: element1?.btn_id || '', children: [],
      is_disabled: element2?.status
    }
      // let obj2 = {name: element2.title, id: element1._id+'-'+element2._id, verbiage_id: element1._id,parent_id: element1._id + '-' + element1?.btn_id || '',children:[]}
      harr.push(obj2);
    }
  }
  const check = convertToHierarchy(harr);
  return check;
}


function convertToHierarchy(inputData) {
  const hierarchy = {};
  inputData.forEach((item) => {
    if (!item.parent_id) {
      hierarchy[item.id] = {
        name: item.name,
        id: item.id,
        verbiage_id: item.verbiage_id,
        children: [],
        is_disabled: item?.status
      };
    } else {
      if (!hierarchy[item.parent_id]) {
        hierarchy[item.parent_id] = {
          name: "Parent Not Found",
          id: item.parent_id,
          verbiage_id: item.verbiage_id,
          children: [],
          is_disabled: item?.status
        };
      }
      hierarchy[item.parent_id].children.push({
        name: item.name,
        id: item.id,
        verbiage_id: item?.verbiage_id,
        children: [],
        is_disabled: item?.status
      });
    }
  });

  const rootItems = Object.values(hierarchy).filter(item => !item.parent_id);

  return rootItems;
}


const ChildTree = (PrentArr,BntArr)=>{
  const res = BntArr?.map((item)=>{
      const cilds = findChild(PrentArr,item?._id) || [];
      return {
          id: item?._id,
          name: item?.title,
          parent_id: '',
          children: cilds,
      }
  });
  return res;
}
const findChild = (arrData,btn_id)=> {
  const res =[];
  arrData?.map((ar)=> {
      if(ar.btn_id===btn_id && ar){
          const childD = ChildTree(arrData,arrData.btn_arr) || []
          res.push({
              id: ar?._id,
              name: ar?.verbiage_title,
              parent_id: '',
              children: childD
          })
      }
  })
  return res || [];
}



export const ConvertToTree = (Data)=>{

  let sattleTree = [];
  Data.map((item,i)=>{
    const parent_status = parseInt(item?.status);
      const pbj = {
          id: item?._id,
          name: item?.verbiage_title,
          verbiage_id: item?._id,
          parent_id: item?.btn_id || '',
          is_enable: parent_status,
      }
      if(i===0){
          sattleTree.push(pbj);
      }
      item?.btn_arr.map((it)=> {
          const pbj2 = {
              id: it?._id,
              name: it?.title,
              parent_id: item?.btn_id || item?._id,
              is_enable:  parseInt(it?.status),
              verbiage_id: item?._id,
          }
          sattleTree.push(pbj2);
      })
  })

  
  const hierarchyTree = buildHierarchyTree(sattleTree);
  return {hierarchyTree,sattleTree};
}

function buildHierarchyTree(nodes, parentID = '',is_enable=1) {
  const tree = [];

  nodes
      .filter(node => node.parent_id === parentID)
      .forEach(node => {
          const children = buildHierarchyTree(nodes, node.id,node.is_enable);
          if (children.length > 0) {
              node.children = children;
              node.children.is_enable = is_enable 
          }
          tree.push(node);
      });

  return tree;
}


export const BASE_IMG = 'https://api.frscripts.com/'; 
// export const BASE_IMG = 'https://callflow.techcarrel.in/'; 