import React from 'react'
import { Route, Routes } from 'react-router'
import Dashboard from './Dashboard/Dashboard'
import AddAgent from './AddAgent/AddAgent'
import AgentList from './AddAgent/AgentList'
import SubAdminScriptDetails from './SubAdminScriptDetails'

const SubAdminRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/agent/add' element={<AddAgent />} />
        <Route path='/agent/list' element={<AgentList />} />
        {/* <Route path='/agent/edit/:id' element={<AgentList />} /> */}
        <Route path='/script-details/:script_id' element={<SubAdminScriptDetails />} />
      </Routes>
    </div>
  )
}

export default SubAdminRoutes