import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../helpers/API';
import AccordionSkeleton from '../../components/skeleton/AccordionSkeleton';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { AddIcon, BackIcon, EditIcon } from '../../helpers/Icons';
import { Link } from 'react-router-dom';
import { ConvertToHierarchy, ConvertToTree, GetScriptDetail, getVerbiagebyId } from '../../helpers/BaseFn';
import { Modal } from 'react-bootstrap';
import EditVerbiage from '../../components/ScriptForm/EditVerbage';
import VerbiageAccordian from '../../components/verbiage_cards/VerbiageAccordian';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router';
import TreeViewData from '../../TreeView';
import TableSkeleton from '../../components/tables/TableSkeleton';
import './script-details-tree.scss';
import TreeViewContainer from '../../components/TreeViewComp/TreeViewContainer';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../redux/Slices/LoadingSlice';

function ScriptDetailsTree() {
   const navigate = useNavigate();
   const params = useParams();
   const { script_id } = params;

   const [loading, setLoading] = useState(false);
   const [verbiageList, setVerbiageList] = useState([]);
   const [verbiageIds, setVerbiageIds] = useState([])
   const [treedata, setTreeData] = useState([])
   const [leadScript, setLeadScript] = useState('script-one');
   const [scriptDetails, setScriptDetails] = useState({});
   const router = ''
   const { query } = router;
   const newTreedata = {
      "name": "",
      "_id": "",
      children: [],
      "isExpanded": true,
   }


   // Access query values
   const verbiageId = query?.verbiage_id || '';
   const dispatch = useDispatch();
   const fetch = async () => {
      setLoading(true);
      dispatch(setReduxLoading(true));
      if (!script_id) return "";
      const res = await CallGETAPI('script/get_verbiage_data?script_id=' + script_id);
      const result = res?.data?.data || [];
      let optioan_val = result.map((item) => ({ ...item, label: item.verbiage_title, }));
      const ids = []
      result.slice(0, 1).map(data =>
         setVerbiageIds(prev => [...prev, data?._id])
      )
      if (verbiageId) {
         optioan_val = optioan_val.filter((item) => (item._id === verbiageId));
      }
      // const treedata1 = ConvertToHierarchy(optioan_val);
      const treedata12 = ConvertToTree(optioan_val);
      const scriptData = await GetScriptDetail(script_id);
      setScriptDetails(scriptData);
      setLeadScript(optioan_val?.[0]?._id);
      setVerbiageList(optioan_val)
      
      // newTreedata["name"] = treedata1[0]?.name
      // newTreedata["id"] = treedata1[0]?.id
      // newTreedata["verbiage_id"] = treedata1[0]?.verbiage_id
      // newTreedata["children"] = (optioan_val?.[0]?.btn_arr.length > 0) ? treedata1?.slice(1, optioan_val?.[0]?.btn_arr.length + 1) : [];
      setTreeData(treedata12?.hierarchyTree)
      setLoading(false);
      dispatch(setReduxLoading(false));
   }
   useEffect(() => {
      fetch();
   }, [script_id, verbiageId])
   const handleDelete = async (id) => {
      const result = await CallGETAPI('script/delete_script?script_id=' + id);
      if (result?.data?.status) {
         toast.success("Delected Successfully");
         navigate("/");
      } else {
         toast.error(result?.data?.message);
      }
   }

   const [openEditModal, setOpenEditModal] = useState(false);
   const [editVerbageId, setEditVerbiageId] = useState({});


   const getVerbiageDetails = async (id) => {
      setOpenEditModal(true);
      let result = await getVerbiagebyId(id);
      setEditVerbiageId(result)
   }


   const handleClose = (checkUpdate = false) => {
      setOpenEditModal(false)
      if (checkUpdate) {
         setVerbiageList([])
         fetch();
      }
   };


   const handleAddScript = (verbiageId, btnId, status) => {

         navigate(`/create-verbiage/${script_id}?verbiage_id=${verbiageId}&btnId=${btnId}`)
      
      setLeadScript(btnId)
   }

   return (
      <>
         <Header />
         <section className="all-scripts py-4">

            {/* top heading */}
            <div className="top-heading bg-light-gray py-4">
               <div className="container d-flex justify-content-between align-items-center">
                  <h4 className='text-gray-heading text-capitalize'>
                     <button className='btn text-primary' type="button"
                        onClick={() => navigate(-1)}
                     ><BackIcon /></button>
                     {scriptDetails?.script_title} : Details
                  </h4>
                  {/* <button className='d-flex gap-2 align-items-center btn text-danger p-0' type="button"

                     onClick={() => {
                        confirmAlert({
                           title: 'Delete Script',
                           message: 'Are you sure to do this.',
                           buttons: [
                              {
                                 label: 'Yes',
                                 onClick: () => handleDelete(script_id)
                              },
                              {
                                 label: 'No',
                                 onClick: () => { }
                              }
                           ]
                        })
                     }}
                  >
                     <img
                        src={"/assets/images/delete.svg"}
                        alt='delete'
                        width={20}
                        height={20}
                     />
                     <span>Delete</span>
                  </button> */}
               </div>
            </div>

            {/* view accordion */}
            <div className="container view-mode mt-4">
            {loading ? <AccordionSkeleton /> :
                            verbiageList?.length === 0 && !loading ?
                                <div className='alert alert-warning' role='alert' >
                                    No Verbiage Found
                                </div>
                                : ""}
               {/* {loading ? <TableSkeleton /> : <TreeViewData
               list={verbiageList} getVerbiageDetails={getVerbiageDetails}
                  handleAddScript={handleAddScript} treedata={treedata} ids={verbiageIds} />} */}
                  {loading ? <TableSkeleton /> : <TreeViewContainer
                     list={verbiageList} 
                     getVerbiageDetails={getVerbiageDetails}
                     handleAddScript={handleAddScript} 
                     ids={verbiageIds}
                     TreeData={treedata}
                  />}


               <Modal show={openEditModal} onHide={handleClose} className='modal-xl' backdrop="static">
                  <Modal.Header closeButton>
                     <Modal.Title>Edit Verbiage</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <EditVerbiage
                        handleClose={handleClose}
                        id={script_id}
                        scriptDetails={scriptDetails}
                        verbiageDetails={editVerbageId}
                        image={scriptDetails?.media?.images}
                        video={scriptDetails?.media?.videos}
                     />
                  </Modal.Body>
               </Modal>

               {/* pagination */}
               {/* <div className='d-flex justify-content-center py-5'>
                <nav>
                    <ul className="pagination">
                        <li className="page-item"><p className="page-link text-light-gray cursor-pointer">&#60; Previous</p></li>
                        <li className="page-item"><p className="page-link active cursor-pointer">1</p></li>
                        <li className="page-item"><p className="page-link cursor-pointer">2</p></li>
                        <li className="page-item"><p className="page-link cursor-pointer">3</p></li>
                        <li className="page-item"><p className="page-link active cursor-pointer">Next &#62;</p></li>
                    </ul>
                </nav>
            </div> */}
            </div>
         </section>

      </>
   )
}


export default ScriptDetailsTree