import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import AccordionSkeleton from '../../../components/skeleton/AccordionSkeleton';
import { CallGETAPI } from '../../../helpers/API';
import { BackIcon } from '../../../helpers/Icons';
import { GetScriptDetail, GetVerbiageByButton } from '../../../helpers/BaseFn';
import Skeleton from 'react-loading-skeleton';
import VerbiageCard from '../../../components/verbiage_cards/VerbiageCard';
import Header_agent from '../../../components/Header-agent';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../../redux/Slices/LoadingSlice';

const SkeletonLoader = () => {
    return (
      <div className="card-body">
        <Skeleton height={20} width={200} style={{ marginBottom: '10px' }} />
        <hr />
        <Skeleton count={2} height={20} style={{ marginBottom: '10px' }} />
  
        <div className='d-flex gap-2'>
          <Skeleton height={20} width={100} style={{ marginRight: '10px' }} />
          <Skeleton height={20} width={100} style={{ marginRight: '10px' }} />
          <Skeleton height={20} width={100} style={{ marginRight: '10px' }} />
        </div>
      </div>
    );
  };

const AgentScriptDetails = () =>{
// {script_id}
    const [loading,setLoading] = useState(false);
    const [verbiageList,setVerbiageList]   = useState([]);
    const [leadScript,setLeadScript]       = useState('script-one');
    const [ScriptData,SetScriptData]       = useState({});
    const [ChatData,setChatData]           = useState([]);
    const dispatch = useDispatch();
    
    const navigate  = useNavigate();
    const params    = useParams();
    const script_id = params?.script_id;

    const fetch  = async ()=>{
        setLoading(true);
        dispatch(setReduxLoading(true));
        if(!script_id) return "";
        const res       = await  CallGETAPI('script/get_verbiage_data?script_id='+script_id);
        const result    = res?.data?.data || [];
        let optioan_val = result.map((item)=>({...item,label:item.verbiage_title,}))
         console.log({result})

        // const ScriptResResult = await GetScriptDetail(script_id);
        const ScriptRes        = await  CallGETAPI('script/get_script_data_id?id='+script_id);

        console.log({ScriptRes})
        const ScriptResResult  = ScriptRes?.data?.data || [];
        const FirstVerbiage    = ScriptRes?.data?.first_verbiage || "";

        SetScriptData(ScriptResResult);
        
        

        let arr = FirstVerbiage ? [FirstVerbiage] : [];
        setChatData(arr);
        setVerbiageList(optioan_val)
        setLoading(false);
        dispatch(setReduxLoading(false));
    }
  
      const handleRedirect = async () => {
       navigate('/agent/dashboard');
      };

    const handleReset = async()=>{
      setChatData([]);
      setVerbiageList([])
      fetch();
    }
      const getVeriageById = async (e,index)=>{
        const btnId = e.target.value;
        index = index + 1;
        setLoading(true);
        let resutl = await GetVerbiageByButton(btnId,script_id);
        if(resutl.length > 0){
            const ChatList  = ChatData.length > 0 ? [...ChatData] : [];
            const filterarr = ChatList.filter((item,i)=> (i < index) && item);
            filterarr[index] = resutl?.[0];
            setChatData(filterarr);
        }else{
            toast.error("No Results Found")
        }
        setLoading(false);
      }


      useEffect(()=>{
        setChatData([]);
        setVerbiageList([])
        fetch();
      },[script_id])


  return (
    <>
    <Header_agent/>
<section className="all-scripts py-4">
    {/* top heading */ }
    <div className="top-heading bg-light-gray py-4">
        <div className="container d-flex justify-content-between align-items-center">
            <h4 className='text-gray-heading' style={{textTransform:'capitalize'}}>
                <Link to="/agent/dashboard"><BackIcon/></Link>
                {ScriptData?.script_title} : Details
            </h4>
        </div>
    </div>


    <div className="container">
        <div className="mx-auto" style={{padding: '50px'}} >
            <div className='conversation cards'>


                {(ChatData.length === 0 && !loading) && 
                <div className="alert alert-danger" > No Data Found</div>
                }
                {ChatData.length > 0  && ChatData.map((item,index)=>(
                    <div key={index} className={`card ${index===0?'':'mt-4'} `}>
                        <VerbiageCard item={item}  getVeriageById={getVeriageById} index={index} />
                    </div>
                ))}

                {loading && <SkeletonLoader/>}

            </div>
            <div style={{display: 'flex',
              width: '100%',
              gap: '20px',
              justifyContent: 'end',
              textAlign: 'right',
              marginTop: '20px'}}> 
            {ChatData?.length > 0 ?
              <button className='btn btn-primary' onClick={handleRedirect}>Back to Beginning</button>
            : " "}
            {ChatData?.length > 1 ?
              <button className='btn btn-primary' onClick={handleReset}>Reset</button>
            : " "}
            </div>
        </div>
    </div>
</section>

    </>
  )
}


export default AgentScriptDetails