import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../helpers/API';
import AccordionSkeleton from '../../components/skeleton/AccordionSkeleton';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { BackIcon } from '../../helpers/Icons';
import { GetScriptDetail, getVerbiagebyId } from '../../helpers/BaseFn';
import { Modal } from 'react-bootstrap';
import EditVerbiage from '../../components/ScriptForm/EditVerbage';
import VerbiageAccordian from '../../components/verbiage_cards/VerbiageAccordian';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router';
import { BsTree } from 'react-icons/bs';
import MyLightBox from '../../components/verbiage_cards/MyLightBox';
import { setReduxLoading } from '../../redux/Slices/LoadingSlice';
import { useDispatch } from 'react-redux';

function ScriptDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const { script_id } = params;
    const [loading, setLoading] = useState(false);
    const [verbiageList, setVerbiageList] = useState([]);
    const [leadScript, setLeadScript] = useState('script-one');
    const [scriptDetails, setScriptDetails] = useState({});
    const router = ''
    const { query } = router;


    // Access query values
    const verbiageId = query?.verbiage_id || '';
    console.log(verbiageId)
    const dispatch = useDispatch();
    const fetch = async () => {
        setLoading(true);
        dispatch(setReduxLoading(true))
        if (!script_id) return "";
        const res = await CallGETAPI('script/get_verbiage_data?script_id=' + script_id);
        const result = res?.data?.data || [];
        console.log(result)
        let optioan_val = result.map((item) => ({ ...item, label: item.verbiage_title, }));

        if (verbiageId) {
            optioan_val = optioan_val.filter((item) => (item._id === verbiageId));
        }
        console.log(optioan_val)
        const scriptData = await GetScriptDetail(script_id);
        setScriptDetails(scriptData);
        setLeadScript(optioan_val?.[0]?._id);
        setVerbiageList(optioan_val)
        setLoading(false);
        dispatch(setReduxLoading(false));
    }
    useEffect(() => {
        fetch();
    }, [script_id, verbiageId])
    const handleDelete = async (id) => {
        const result = await CallGETAPI('script/delete_script?script_id=' + id);
        if (result?.data?.status) {
            toast.success("Delected Successfully");
            navigate("/");
        } else {
            toast.error(result?.data?.message);
        }
    }



    const [openEditModal, setOpenEditModal] = useState(false);
    const [editVerbageId, setEditVerbiageId] = useState({});
    const [isUpdated, setisUpdated] = useState(false);
    const [lightboxOpen, setLightboxOpen] = React.useState(false);
const [combinedMedia,setcombinedMedia]  = useState([])

    const getVerbiageDetails = async (id) => {
        setOpenEditModal(true);
        let result = await getVerbiagebyId(id);
        console.log({ result });
        setEditVerbiageId(result)
    }


    const handleClose = (checkUpdate = false) => {
        setOpenEditModal(false)
        if (checkUpdate) {
            setVerbiageList([])
            fetch();
        }
    };



    const handleAddScript = (verbiageId, btnId, status) => {
        console.log("clicked")
        if (status === 0) {
            navigate(`/create-verbiage/${script_id}?verbiage_id=${verbiageId}&btnId=${btnId}`)
        }
        setLeadScript(btnId)
    }


    return (
        <>
            <Header />
            <section className="all-scripts py-4">
                {/* top heading */}
                <div className="top-heading bg-light-gray py-4">
                    <div className="container d-flex justify-content-between align-items-center">
                        <h4 className='text-gray-heading text-capitalize'>
                            <button className='btn text-primary' type="button"
                                onClick={() => navigate(-1)}
                            ><BackIcon /></button>
                            {scriptDetails?.script_title} : Details
                        </h4>
                        <div className='d-flex'>
                            <button className='d-flex gap-2 align-items-center btn text-primary mx-2 p-0' type="button"
                                onClick={() => {
                                    navigate(`/script-details/tree-view/${script_id}`)
                                }}
                            >
                                <BsTree size={20} />
                                <span>View Tree</span>
                            </button>
                            {/* <button className='d-flex gap-2 align-items-center btn text-danger p-0' type="button"
                                onClick={() => {
                                    confirmAlert({
                                        title: 'Delete Script',
                                        message: 'Are you sure to do this.',
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: () => handleDelete(script_id)
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => { }
                                            }
                                        ]
                                    })
                                }}
                            >
                                <img
                                    src={"/assets/images/delete.svg"}
                                    alt='delete'
                                    width={20}
                                    height={20}
                                />
                                <span>Delete</span>
                            </button> */}

                        </div>
                    </div>
                </div>

                {/* view accordion */}
                <div className="container view-mode mt-4">

                    <div className="accordion" id="accordionExample">
                        {loading ? <AccordionSkeleton /> :
                            verbiageList?.length === 0 && !loading ?
                                <div className='alert alert-warning' role='alert' >
                                    No Verbiage Found
                                </div>
                                : ""}
                        {verbiageList?.length > 0 ? verbiageList.map((item, index) => (

                            <div className='' key={index} >
                                <VerbiageAccordian
                                    index={index}
                                    item={item}
                                    leadScript={leadScript}
                                    setLeadScript={setLeadScript}
                                    getVerbiageDetails={getVerbiageDetails}
                                    handleAddScript={handleAddScript}
                                    images={item?.media?.images}
                                    videos={item?.media?.videos}
                                    setLightboxOpen={setLightboxOpen}
                                    setcombinedMedia={setcombinedMedia}
                                />
                            </div>
                        )) : ""}
                    </div>
                    {1 &&  
                                        <MyLightBox
                            open={lightboxOpen}
                            setOpen={setLightboxOpen}
                            srcList={combinedMedia}
                        /> }
                    <Modal show={openEditModal} onHide={handleClose} className='modal-xl' backdrop="static" >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Verbiage</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditVerbiage
                                handleClose={handleClose}
                                id={script_id}
                                scriptDetails={scriptDetails}
                                verbiageDetails={editVerbageId}
                                image={scriptDetails?.media?.images}
                                video={scriptDetails?.media?.videos}
                            />
                        </Modal.Body>
                    </Modal>
                    {/* pagination */}
                    {/* <div className='d-flex justify-content-center py-5'>
                <nav>
                    <ul className="pagination">
                        <li className="page-item"><p className="page-link text-light-gray cursor-pointer">&#60; Previous</p></li>
                        <li className="page-item"><p className="page-link active cursor-pointer">1</p></li>
                        <li className="page-item"><p className="page-link cursor-pointer">2</p></li>
                        <li className="page-item"><p className="page-link cursor-pointer">3</p></li>
                        <li className="page-item"><p className="page-link active cursor-pointer">Next &#62;</p></li>
                    </ul>
                </nav>
            </div> */}
            
                </div>
                {/* <div className='container mt-3' >
                {loading ? <AccordionSkeleton /> :
                            verbiageList?.length > 0 && !loading ?
                            <button className='align-items-center btn text-white p-2 ' type="button"
                            style={{ background: '#ff0000', color: 'white',  border: 'none', width:'10%' 
                                
                              }}
                            onClick={() => {
                                confirmAlert({
                                    title: 'Delete Script',
                                    message: 'Are you sure to do this.',
                                    buttons: [
                                        {
                                            label: 'Yes',
                                            onClick: () => handleDelete(script_id)
                                        },
                                        {
                                            label: 'No',
                                            onClick: () => { }
                                        }
                                    ]
                                })
                            }}
                        >
                            {/* <img
                                src={"/assets/images/delete.svg"}
                                alt='delete'
                                width={20}
                                height={20}
                                style={{marginRight: '10px', verticalAlign: 'text-bottom',textAlign:'left'}}
                            /> */}
                            {/* <span>Delete</span>
                        </button>
                                : ""}
                
                </div> */} 
            </section>

        </>
    )
}


export default ScriptDetails