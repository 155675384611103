import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AccordionSkeleton = () => {
  const arr = [1,2,3,4,5]
  return (
    <>
    {arr.map((item)=>(
      <div className="accordion-item" key={item}>
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button d-flex gap-2 justify-content-between align-items-center"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span>
                <Skeleton width="100px" height="20px" />
              </span>
              <p className="text-primary btn p-0 d-flex gap-2 align-items-center cursor-pointer">
                <Skeleton width="20px" height="20px" />
                <span>
                  <Skeleton width="50px" height="15px" />
                </span>
              </p>
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                <Skeleton count={2} height="20px" />
              </p>
              {/* buttons */}
              <div className="view-button-group mt-4 mb-2">
                <div className="form-group">
                  <Skeleton width="100px" height="30px" />
                </div>
                <div className="form-group">
                  <Skeleton width="200px" height="30px" />
                </div>
                <div className="form-group">
                  <Skeleton width="150px" height="30px" />
                </div>
                <div className="form-group">
                  <Skeleton width="100px" height="30px" />
                </div>
                <div className="form-group">
                  <Skeleton width="200px" height="30px" />
                </div>
                <div className="form-group">
                  <Skeleton width="150px" height="30px" />
                </div>
              </div>
            </div>
          </div>
      </div>
    ))}</>
  );
};

export default AccordionSkeleton;
