import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


function TableSkeleton() {
  return (
    <>

      <tbody className=''>
        

      <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>


    <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>


    <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>


    <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>

    <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>

    <tr>
      <td className="text-heading py-3">
        <Skeleton width="200px" height="20px" />
      </td>
      <td className="text-gray-light text-center py-3">
        <Skeleton width="100px" height="20px" />
      </td>
      <td className="text-primary text-center py-3 d-flex justify-content-center">
        <Skeleton width="50px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="80px" height="20px" />
        &nbsp; &nbsp;
        <Skeleton width="60px" height="20px" />
      </td>
    </tr>

        
        {/* Add more rows as needed */}
      </tbody>
    </>
  )
}

export default TableSkeleton