import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { CallPOSTAPI } from '../helpers/API';
import { toast } from 'react-toastify';
import { getUsers } from '../helpers/BaseFn';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ForgotPassword from './Auth/ForgotPassword';
import { Padding } from '@mui/icons-material';
import 'bootstrap-icons/font/bootstrap-icons.css';
const Login = () => {
  const [loading, setLoading] = useState({ status: false, loading_status: false, msg: "" });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  // const [isRememberMe, setisRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();


 // Load rememberMe state from local storage on component mount
//  useEffect(() => {
//   const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
//   setisRememberMe(rememberMeValue);
// }, []);

// Save rememberMe state to local storage when it changes
// useEffect(() => {
//   localStorage.setItem('rememberMe', isRememberMe);
// }, [isRememberMe]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading({ status: false, loading_status: true, msg: "" });
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading({ status: false, loading_status: false, msg: "" });
    } else {
      // Perform login logic here
      // For simplicity, let's just log the email and password
      const send_obj = {
        email: email,
        password: password
      }
      let res = await CallPOSTAPI('api_auth/login', send_obj);
      if (res?.data?.status) {
        let token = res?.data?.token;
        localStorage.setItem('callflow_token', token);
        toast.success('Login Successful');

        let userData = getUsers(token)
        if (parseInt(userData?.role_type) === 1) {
          navigate('/dashboard');
        } else if (parseInt(userData?.role_type) === 5) {
          navigate('/agent/dashboard');
        }
        else if (parseInt(userData?.role_type) === 4) {
          navigate('/sub-admin/dashboard');
        }
        

      } else {
        toast.error(res?.data?.message);
      }
      setLoading({ status: false, loading_status: false, msg: "" });
    }
    setValidated(true);
  };

  useEffect(() => {
    let token = localStorage.getItem('callflow_token');
    if (token) {
      let userData = getUsers(token)
      console.log(userData)
      if (parseInt(userData?.role_type) == 1) {
        navigate('/dashboard');
      } else if (userData?.role_type == 5) {
        navigate('/agent/dashboard');
      } else if (userData?.role_type == 4) {
        navigate('/sub-admin/dashboard');
      }
    }

  }, [localStorage.getItem('callflow_token')])

  const [is_forgotpwd,setisForgotPWd] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (

    <>

      <section className="all-scripts py-4">

        {/* top heading */}



        <div className="container login-container">

          <div className="navbar-brand text-center">
            <img
              src={"/assets/images/logo.png"}
              alt='logo'
              width={180}
              // height={50}
            />
            <h4 className='text-gray-heading'>{is_forgotpwd ? 'Forgot Password' :"Welcome To FrScript"}</h4>
          </div>
            {is_forgotpwd ? <ForgotPassword setisForgotPWd={setisForgotPWd}/> : 
            <div className="main-form p-0 text-left " style={{ maxWidth: '100%', marginTop: "2rem" }} >
             
              <h4 style={{textAlign:'center'}}>Login </h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className='mt-2' controlId="formEmail">
                  <Form.Label>Email address <span style={{ color: 'red',fontSize: '14px' }}>*</span> </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{borderRadius: '0'}}
                  />
                  <Form.Control.Feedback type="invalid">
                    {email?'Please provide a valid email.' :'Email is Required'}
                  </Form.Control.Feedback>
                </Form.Group>

                <div>
      <Form.Group className='mt-5' controlId="formPassword">
        <Form.Label>Password <span style={{ color: 'red', fontSize: '14px' }}>*</span></Form.Label>
        <div className="input-container">
       
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{borderRadius: '0'}}
          />

          <div className='relative' >
          <span
        className="password-toggle-icon"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
           <i className="bi bi-eye"></i> 
        ) : (
          <i className="bi bi-eye-slash"></i>
         
        )}
      </span>
          </div>
          <Form.Control.Feedback type="invalid">
                    {'Password is Required'}
              </Form.Control.Feedback>
        
       
        </div>
       

        <div className="justify-content-between mt-2" style={{textAlign: 'right'}}>
          {/* <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="rememberMe"
              checked={isRememberMe}
              onChange={() => setisRememberMe(!isRememberMe)}
              style={{
                width: "20px",
                height: "15px",
                marginRight: "6px",
                padding: "8px",
              }}
            />
            <label
              style={{ fontSize: '12px' }}
              className="form-check-label small ml-1"
              htmlFor="rememberMe"
            >
              Remember Me
            </label>
          </div> */}
          <div style={{ marginBottom: "10px" }}>
            <button
              className='btn btn-link text-primary p-0 ' style={{ fontSize: "13px" }}
              onClick={() => setisForgotPWd(true)}
              tabIndex={-1}
              type="button"
            >
              Forgot Password?
            </button>
          </div>
        </div>

        <Form.Control.Feedback type="invalid">
          Please provide a password.
        </Form.Control.Feedback>
      </Form.Group>
    </div>


                <div className='mt-5' style={{ justifyContent: 'right' }}>
                  <Button variant="primary" type="submit" style={{width: "100%", justifyContent: "center", alignItem:"center", height:"40px"}}
                    tabIndex={1}
                  className='mt-4 ml-auto d-flex' disabled={loading.loading_status} >
                    {loading.loading_status ? "Loading..." : "Login"}
                  </Button>
                </div>
              </Form>
            </div>
          }
        </div>

      </section>
    </>

  );
};

export default Login;
