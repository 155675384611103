import { toast } from "react-toastify";

// export const BASE_API = 'https://call-flow-tc.onrender.com/'; 
export const BASE_API = 'https://api.frscripts.com/';
// export const BASE_API    = 'https://api.frscripts.com/';//https://api.frscripts.com/';
// https://callflow.techcarrel.in/
//export const BASE_API = 'https://bidmate-tc-2.onrender.com/'; // http://localhost:3005


export async function CallGETAPI(url, params = "", headers = {}) {
    try {
        let token = localStorage.getItem('callflow_token');
        let AllHeaders = { ...headers };
        if (token) {
            AllHeaders = { Authorization: token }
        }
        let res = await fetch(BASE_API + url + params, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', ...AllHeaders
            }
        });

        if (!res.ok) {
            return { status: false, msg: 'No Data Found', data: [] }
        }
        let resultData = await res.json();

        if (resultData.message === 'Invalid token.Unauthorized access.') {
            localStorage.removeItem('callflow_token');
            toast.error('Session Out Please Login')
        }

        return { status: true, msg: '', data: resultData }
    } catch (e) {
        return { status: false, msg: e.message, data: [] }
    }
}


export async function CallPOSTAPI(url, params = [], headers = {}) {
    try {
        let token = localStorage.getItem('callflow_token');
        let AllHeaders = { ...headers };
        if (token) {
            AllHeaders = { Authorization: token, ...headers }
        }

        let res = await fetch(BASE_API + url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...AllHeaders },
            body: JSON.stringify(params)
        });

        if (!res.ok) {
            return { status: false, msg: 'No Data Found', data: [] }
        }
        let resultData = await res.json();
        return { status: true, msg: '', data: resultData }
    } catch (e) {
        return { status: false, msg: e.message, data: [] }
    }
}
export async function CallPOSTFORMAPI(url, send_data, file, headers = {},is_edit=false) {
    try {
        const formData = new FormData()

        var ins = file?.length;
        for (var x = 0; x < ins; x++) {
            formData.append("media", file[x]);
        }
        send_data?.btn_arr?.map((data,i)=>{
            formData.append(`btn_arr[${i}][title]`, data?.title)
            if(is_edit){
            formData.append(`btn_arr[${i}][status]`, data?.status)
            formData.append(`btn_arr[${i}][no_objection]`, data?.no_objection)
            formData.append(`btn_arr[${i}][is_created]`, data?.is_created)
            formData.append(`btn_arr[${i}][_id]`, data?._id || '')
            }
            // status: 0, is_created: '1', no_objection: '0', _id: '64e70bdc696b19193eaa666e'
        })

        if(send_data?.old_media && is_edit){
            // old_media
            send_data?.old_media?.images.map((item,i)=>{
                formData.append(`old_images[${i}]`, item)
            })
            send_data?.old_media?.videos.map((item,i)=>{
                formData.append(`old_videos[${i}]`, item)
            })
        }
        // old_media
        formData.append("verbiage_title", send_data.verbiage_title)
        // formData.append("images", JSON.stringify(send_data.media.images))
        // formData.append("videos", JSON.stringify(send_data.media.videos))
        formData.append("script_id", send_data.script_id)
        formData.append("verbiage_description", send_data.verbiage_description)
        
        formData.append("btn_id", send_data.btn_id)
        formData.append("verbiage_id", send_data.verbiage_id)

        let token = localStorage.getItem('callflow_token');
        let AllHeaders = { ...headers };
        if (token) {
            AllHeaders = { Authorization: token, ...headers }
        }

        let res = await fetch(BASE_API + url, {
            method: 'POST',
            // "Content-Type": "multipart/form-data",
            headers: { ...AllHeaders },
            body: formData
        });

        if (!res.ok) {
            return { status: false, msg: 'No Data Found', data: [] }
        }
        let resultData = await res.json();
        return { status: true, msg: '', data: resultData }
    } catch (e) {
        return { status: false, msg: e.message, data: [] }
    }
}