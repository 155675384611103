// import React from 'react'

// function ScriptSelector() {
//   return (
//     <>
        
//     </>
//   )
// }

// export default ScriptSelector

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ScriptSelector({optionList,setResult,value}) {

  return (
    <>
      <Autocomplete
        key={value}
        disablePortal
        value={value}
        id="combo-box-demo"
        options={optionList}
        getOptionLabel={(option) => option.verbiage_title}
        sx={{ width: '100%' }}
        onChange={(event, newValue) => {
          setResult(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Select Verbiage Title..." />}
      />
    </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
];
