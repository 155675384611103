import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import './style.css'
import { toast } from 'react-toastify';
import { CallPOSTAPI } from '../../../helpers/API';
import { GetScripts, GetSubAdminByID, UpdateSubAdmin } from '../../../helpers/BaseFn';
import Header from '../../../components/Header';
import { BackIcon } from '../../../helpers/Icons';
import { useNavigate, useParams } from 'react-router';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../../../redux/Slices/LoadingSlice';
import Select from 'react-select'

const EditSubAdminForm = ({subAdminId='',refreashList,handleClose}) => {
   const navigate = useNavigate()
   const [loading, setLoading] = useState(false);
   const [updateLoading, setUpdateLoading] = useState(false);
   const [loading2, setLoading2] = React.useState({
      loading_status: false,
      msg: '',
   });
   const params = useParams()
   const id = subAdminId || params.id
   const [details, setDetails] = useState({
      first_name: "",
      email: "",
      last_name: "",
   })

   const handleCancel = () => {
      handleClose();
    };

   const handleChange = (e) => {
      setDetails({
         ...details, [e.target.name]: e.target.value
      })
   }

   const handleNameChange = (e) => {
      const value = e.target.value;
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setDetails({
         ...details, [e.target.name]: e.target.value
      })}else{
         return '';
      }
   }
   // handleNameChange
   const [selectedOptions, setSelectedOptions] = useState([]);
   const onSelectOptions = (selectedList, selectedItem) => {
      console.log({selectedItem})
      if(selectedItem){
         setSelectedOptions([...selectedOptions, selectedItem]);
      }
   };
   const onRemoveOptions = (selectedList, removedItem) => {
      setSelectedOptions(selectedList)
   };
   const [options, setOptions] = useState([])
   const dispatch = useDispatch();
   const LoadPage = async () => {
      setLoading(true);
      // dispatch(setReduxLoading(true));
      setOptions([])
      let result = await GetScripts();
      const res2 = await GetSubAdminByID(id)
      // console.log({res:res2?.subadmin?.data})
      result?.map((data) => {
         const obj = {
            label: data?.script_title,
            value: data?._id
         }
         setOptions((prev) => [...prev, obj])
      })
      setDetails({ ...details, ["first_name"]: res2?.subadmin?.data?.first_name, ["last_name"]:  res2?.subadmin?.data?.last_name, ["email"]:  res2?.subadmin?.data?.email })

      res2?.subadmin?.data?.scripts?.length > 0 && res2?.subadmin?.data?.scripts?.map((script, i) => {
         result?.map((scr) => {
            if (scr?._id == script) {
               const obj = {
                  label: scr?.script_title,
                  value: scr?._id
               }
               setSelectedOptions((prev) => [...prev, obj])
            }
         })
      })
      // result?.map((data) => {
      //    const obj = {
      //       label: data?.script_title,
      //       value: data?._id
      //    }
      //    setOptions((prev) => [...prev, obj])
      // })

      setLoading(false);
      // dispatch(setReduxLoading(false));
   }

   console.log(options)
   const submitForm = async (e) => {

      e.preventDefault();
      setUpdateLoading(true)
      const scriptsSelected = [];
      selectedOptions?.map((data) => {
         scriptsSelected.push(data?.value)
      })
      const data = {
         first_name: details.first_name,
         last_name: details.last_name,
         email: details.email,
         id: id,
         scripts: scriptsSelected,
      }
      console.log(data)

      setLoading2((prevState) => ({ ...prevState, loading_status: true }));
      let res = await  CallPOSTAPI('api_auth/update_subadmin/'+id,data);
      setLoading2((prevState) => ({ ...prevState, loading_status: false }));
      console.log({res})
      if (res?.status) {
         toast.success("Subadmin Updated Successfully");
         refreashList();
         handleClose();
         //   console.log(res?.data?.data?._id);    
         //   let id = res?.data?.data?._id;
         //   navigate('/create-verbiage/'+id);
      } else {
         toast.error("Something went wrong.  please try again");
      }
      setUpdateLoading(false)

   }

   useEffect(() => { LoadPage() }, [])
   return (
      <>
      {!subAdminId && 
      <>
         <Header />
         <div className="top-heading bg-light-gray py-4">
            <div className="container d-flex justify-content-start align-items-center">
               <h4 className='text-gray-heading text-capitalize modal-title'>
                  <button className='btn text-primary' type="button"
                     onClick={() => navigate(-1)}
                  ><BackIcon /></button>
                  Edit SubAdmin
               </h4>

            </div>
         </div>
         </>}
         {loading ? <div className='form-loader' style={{minHeight:'200px'}}>
          <div class="uploading-animation form-loader" id="uploading-animation"  > 
          </div>
         </div> : 
         <Form onSubmit={submitForm} className='col-12 m-auto my-2' > 
         {/* <h4 style={{textAlign: 'center', marginBottom: '5rem'}}>Edit SubAdmin</h4>         */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className='row'>
            <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>First Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="first_name"
                     value={details?.first_name}
                     // onChange={handleChange}
                     onChange={handleNameChange}
                     placeholder="Enter First Name" 
                     style={{borderRadius: '0'}} />
               </Form.Group>
               </div>
               <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Last Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="last_name"
                     value={details?.last_name}
                     onChange={handleNameChange}
                     placeholder="Enter Last Name"
                     style={{borderRadius: '0'}} />
               </Form.Group>
               </div>
               </div>
               <Form.Label>Email address  <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
               <Form.Control required type="email"
                  name="email"
                  value={details?.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  style={{borderRadius: '0'}} />
            </Form.Group>

            <div className='mb-5 pb-5'>
               <Form.Label>Add Scripts</Form.Label>

               {/* <Multiselect
                  options={options}
                  name="particulars"
                  onSelect={onSelectOptions}
                  onRemove={onRemoveOptions}
                  displayValue="label"
                  closeIcon="cancel"
                  placeholder="Select Options"
                  selectedValues={selectedOptions}
                  // className="multiSelectContainer"
               /> */}
               <Select options={options}
                  name="particulars"
                  // onChange={onSelectOptions}
                  defaultValue={selectedOptions}
                  isMulti
                  placeholder="Select Options"
                  className="multiSelectContainer"
                  onChange={(e)=>{
                     setSelectedOptions(e)
                  }}
                  closeMenuOnSelect={false}
                  // onSelectOptions={onSelectOptions}
               />
            </div>
            <div className="d-flex justify-content-end">
            <Button disabled={loading2.loading_status} style={{ width: "150px", marginRight: "10px" }} variant="secondary"onClick={handleCancel}> Cancel </Button>
               <Button disabled={updateLoading} style={{ width: "150px" }} variant="primary" type="submit">
                  {updateLoading ? (<Spinner size="sm" animation="border" />) : ("Update")}
               </Button>
            </div>
         </Form>
         }
      </>
   )
}

export default EditSubAdminForm