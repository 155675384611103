import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import TableSkeleton from '../components/tables/TableSkeleton';
import { GetDeletedScript, GetScriptDetail, GetScripts } from '../helpers/BaseFn';
import moment from 'moment/moment';
import { CallGETAPI } from '../helpers/API';
import { toast } from 'react-toastify';
import ScriptDetailsDrop from '../components/dropdowns/ScriptDetailsDrop';
import Modal from 'react-bootstrap/Modal';
import EditScript from '../components/ScriptForm/EditScript';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';
import { ActivateIcon, TrashBtn } from '../helpers/Icons';
import Header from '../components/Header';
import { useDispatch } from 'react-redux';
import { setReduxLoading } from '../redux/Slices/LoadingSlice';
import Pagination from '@mui/material/Pagination';
import DashboardTbl from '../components/tables/DashboardTbl';
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [scriptList, setScriptList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dispatch = useDispatch();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [scriptDetails, setScriptDetails] = useState({});
  const [editLoading, setEditLoading] = useState(false);
  const [deactivatedScripts, setDeactivatedScripts] = useState([]);
  const [activeScripts, setActiveScripts] = useState([]);

  // Separate state variables for pagination
  const [activeScriptsCurrentPage, setActiveScriptsCurrentPage] = useState(1);
  const [deactivatedScriptsCurrentPage, setDeactivatedScriptsCurrentPage] = useState(1);

  const handleClose = () => {
    
    setOpenEditModal(false);
  };

  const handleShow = async (id) => {
    setEditLoading(true);
    let res = await GetScriptDetail(id);
    setScriptDetails(res);
    setEditLoading(false);
    setOpenEditModal(true);
  };
  const columns = ['_id','script_title','render_action']
  const LoadPage = async () => {
    dispatch(setReduxLoading(true));
    setLoading(true);
    let result        = await GetScripts();
    let resultDetails = await GetDeletedScript();
    setScriptList(result);
    setDeactivatedScripts(resultDetails);
    setLoading(false);
    dispatch(setReduxLoading(false));
  };

  const handleDelete = async (id) => {
    const result = await CallGETAPI('script/delete_script?script_id=' + id + '&status=0');
    if (result?.data?.status) {
      toast.success("Deactivated Successfully");
      LoadPage();
    } else {
      toast.error(result?.data?.message);
    }
  };

  const handleActivate = async (id) => {
    const result = await CallGETAPI('script/delete_script?script_id=' + id + '&status=1');
    if (result?.data?.status) {
      toast.success("Activated Successfully");
      LoadPage();
    } else {
      toast.error(result?.data?.message);
    }
  };

  const handlePageChange = (newPage, isDeactivated) => {
    if (isDeactivated) {
      setDeactivatedScriptsCurrentPage(newPage);
    } else {
      setActiveScriptsCurrentPage(newPage);
    }
  };

  useEffect(() => {
    LoadPage();
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentDeactivatedScripts = deactivatedScripts.slice(
    (deactivatedScriptsCurrentPage - 1) * itemsPerPage,
    deactivatedScriptsCurrentPage * itemsPerPage
  );
  const currentScripts = scriptList.slice(startIndex, endIndex);

  return (
    <>
      <Header />

      <main>
        {/* all scripts */}
        <section className="all-scripts py-4">
          {/* top heading */}
          <div className="top-heading bg-light-gray py-4">
            <div className="container d-flex justify-content-between align-items-center">
              <h4 className="text-gray-heading">All Scripts</h4>
              <div>
                <Link className="btn text-primary mx-2" to={"/create-scripts"}>
                  + Add New Script
                </Link>
                <Link className="btn text-primary p-0" to={"/all/sub-admin"}>
                  View Subadmins
                </Link>
              </div>
            </div>
          </div>

          {/* table */}
          <div className="container">
            <Tabs className={'mt-5'}>
              <TabList>
                <Tab>Active Scripts</Tab>
                <Tab>Deactivated Scripts</Tab>
              </TabList>

              <TabPanel>
              {scriptList?.length === 0 && !loading ? (
                    <tbody>
                      <tr>
                        <td colSpan={3} className="alert alert-warning">
                          No Data Found
                        </td>
                      </tr>
                    </tbody>
                  ) : 
                <DashboardTbl 
                  data={scriptList}
                  handleDelete={handleDelete}
                  setOpenEditModal={setOpenEditModal}
                  handleShow={handleShow}
                  is_deactivated={false}
                  handleActivate={handleActivate}
                />
                }
              </TabPanel>

              <TabPanel>
                <DashboardTbl 
                  data={deactivatedScripts}
                  handleDelete={handleDelete}
                  setOpenEditModal={setOpenEditModal}
                  handleShow={handleShow}
                  is_deactivated={true}
                  handleActivate={handleActivate}
                />
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <Modal show={openEditModal} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Edit Script</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditScript handleClose={handleClose} scriptDetails={scriptDetails} editLoading={editLoading}
            refeshScripts={LoadPage}
            />
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default Dashboard;
