export const TreeData =  [
    {
        "_id": "64c92ecda29934a2b7aaca3e",
        "script_id": "64c8278da29934a2b7aaca2e",
        "verbiage_title": "Connecting Stage - Takes the focus off you, and puts it on them...be sure to be neutral, calm and detached from making the sale in your tone...focus on if we can help them!",
        "verbiage_description": "<p>Hey_____, this is <strong>just</strong>_____I'm the guy/girl who was assigned to you…it looks like you recently submitted your name and info about <strong>possibly</strong> looking at different health insurance options and I just had time to get back to you to see if we could help.</p>",
        "status": "1",
        "btn_arr": [
            { "title": "No objection", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca3f" },
            { "title": "Not interested", "status": "0", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca40" },
            { "title": "I'm sick of these calls", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca41" },
            { "title": "Take me off your list", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca42" },
            { "title": "I already got that taken care of ", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca43" },
            { "title": "I don't need insurance", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca44" },
            { "title": "I didn't request information", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92ecda29934a2b7aaca45" },
            { "title": "Not interested", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c94935a29934a2b7aad47f" },
            { "title": "Time objections (I'm at work or I'm driving)", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c94ce7a29934a2b7aad6c4" }
        ],
        "createdAt": "2023-08-01T16:11:57.840Z",
        "updatedAt": "2023-08-01T23:12:01.510Z",
        "__v": 0,
        "label": "Connecting Stage - Takes the focus off you, and puts it on them...be sure to be neutral, calm and detached from making the sale in your tone...focus on if we can help them!"
    },
    {
        "_id": "64c92f78a29934a2b7aaca5e",
        "script_id": "64c8278da29934a2b7aaca2e",
        "verbiage_title": "No objection",
        "verbiage_description": "<p>I should probably start off by asking do you have current coverage?</p>",
        "status": "1",
        "btn_id": "64c92ecda29934a2b7aaca3f",
        "btn_arr": [
            { "title": "Yes *", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c92f78a29934a2b7aaca5f" },
            { "title": "No *", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c941dda29934a2b7aacf87" }
        ],
        "createdAt": "2023-08-01T16:14:48.650Z",
        "updatedAt": "2023-08-02T00:13:50.362Z",
        "__v": 0,
        "label": "No objection"
    },
    {
        "_id": "64c93661a29934a2b7aaca9e",
        "script_id": "64c8278da29934a2b7aaca2e",
        "verbiage_title": "No objection",
        "verbiage_description": "",
        "status": "1",
        "btn_id": "64c92ecda29934a2b7aaca40",
        "btn_arr": [],
        "createdAt": "2023-08-01T16:44:17.096Z",
        "updatedAt": "2023-08-01T17:33:41.991Z",
        "__v": 0,
        "label": "No objection"
    },
    {
        "_id": "64c93688a29934a2b7aacabe",
        "script_id": "64c8278da29934a2b7aaca2e",
        "verbiage_title": "I'm sick of these calls",
        "verbiage_description": "<p>Oh I apologize, I didn't mean to offend you…just so you're aware, I'm not quite sure we could even help you yet, I'd have to know a bit more about what type of coverage you have now, and what we could qualify you for, just to see if we could even help you in the first place because there's some people there's not much we can do.</p><p>Would you be opposed to having a brief conversation to see if we could get something for you more affordable?</p>",
        "status": "1",
        "btn_id": "64c92ecda29934a2b7aaca41",
        "btn_arr": [
            { "title": "Yes", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c93688a29934a2b7aacabf" },
            { "title": "No", "status": "1", "is_created": "1", "no_objection": "0", "_id": "64c93688a29934a2b7aacac0" }
        ],
        "createdAt": "2023-08-01T16:44:56.986Z",
        "updatedAt": "2023-08-01T17:33:56.750Z",
        "__v": 0,
        "label": "I'm sick of these calls"
    }]



