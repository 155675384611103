import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CallPOSTAPI } from '../../../helpers/API';
import { GetScripts } from '../../../helpers/BaseFn';
import Header from '../../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { BackIcon } from '../../../helpers/Icons';
import Select from 'react-select'
import Multiselect from 'multiselect-react-dropdown';
import './style.css';

const SubAdminForm = ({refreashList,handleClose}) => {  
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading2, setLoading2] = useState({
    loading_status: false,
    msg: '',
  });
  const [details, setDetails] = useState({
    first_name: '',
    email: '',
    last_name: '',
  });

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isBlank, setIsBlankValid] = useState(true);
  const [isBlank1, setIsBlankValid1] = useState(true);
  const [isMenuOpen,setisMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;


    // Check if the input value contains only letters and spaces for first_name and last_name
    if ((name === "first_name" || name === "last_name") && (/^[A-Za-z]+( [A-Za-z]*)*$/.test(value) || value === '')) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
    
    if (name === "email") {
      setDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };
  
  const handleCancel = () => {
    handleClose();
  };

  const handleEmailBlur = (event) => {
    const { name, value } = event.target;

    // Email validation using a regular expression
    const isValidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value);
    setIsEmailValid(isValidEmail);

    // Show error message if email is invalid
    if (!isValidEmail) {
      // toast.error("Invalid email format");
    }

    
  };
  const handleBlank = (event) => {
    const { name, value } = event.target;
    const isBlank  = /\S+/.test(value)
    setIsBlankValid(isBlank)

    // Show error message if email is invalid
    if (!isBlank1) {
      // toast.error("Invalid email format");
    }

    
  };
  const handleBlank1 = (event) => {
    const { name, value } = event.target;
    const isBlank1 = /\S+/.test(value)
    setIsBlankValid1(isBlank1)

    // Show error message if email is invalid
    if (!isBlank) {
      // toast.error("Invalid email format");
    }

    
  };
  const isFormValid = () => {
    return isEmailValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    // Rest of the submitForm logic
  };

  const onSelectOptions = (selectedList, selectedItem) => {
    setSelectedOptions([...selectedOptions, selectedItem]);
  };
  const onRemoveOptions = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
  };

  const LoadPage = async () => {
    let result = await GetScripts();
    result?.map((data) => {
      const obj = {
        label: data?.script_title,
        value: data?._id,
      };
      setOptions((prev) => [...prev, obj]);
    });
  };
  const checkKeyPress = (e)=>{
    e.preventDefault();
    e.stopPropagation();
    return "";
  }

  const submitForm = async (e) => {
    e.preventDefault();
    // console.log({e});
    // alert(e.key);
    // return "";
    // if (e.key === 'Enter') {
    //   e.preventDefault();
    //   return "";
    // }
    const scriptsSelected = [];
    selectedOptions.map((data) => {
      scriptsSelected.push(data?.value);
    });
    const data = {
      first_name: details.first_name,
      last_name: details.last_name,
      email: details.email,
      scripts: scriptsSelected,
    };
    console.log(data);
    setLoading2((prevState) => ({ ...prevState, loading_status: true }));
    let res = await CallPOSTAPI('api_auth/add_subadmin', data);
    console.log(res);
    setLoading2((prevState) => ({ ...prevState, loading_status: false }));
    if (res?.data?.status) {
      setDetails({
        first_name: '',
        email: '',
        last_name: '',
      });
      setSelectedOptions([]);
      toast.success('Subadmin Added Successfully');
      refreashList();
    } else {
      toast.error(res?.data?.message);
      // if (res?.message) {
      //   toast.error(e.msg);
      // } else {
      //   toast.error('Something went wrong.  please try again');
      //    if (res?.message) {
      //       toast.error(e.msg)
      //    }
      //    else {
      //       toast.error("Email Already Existing.Please try again");
      //    }
      // }
    }
  };

  useEffect(() => {
    LoadPage();
  }, []);

  return (
    <>
      {/* <Header />
      <div className="top-heading bg-light-gray py-4">
        <div className="container d-flex justify-content-between align-items-center">
          <h4 className='text-gray-heading text-capitalize'>
            <button className='btn text-primary' type="button"
              onClick={() => navigate(-1)}
            ><BackIcon /></button>
            Add SubAdmin
          </h4>
          <div>
            <Link className='btn text-primary mx-2' to={"/all/sub-admin"} >View Sub Admin</Link>
          </div>
        </div>
      </div> */}
      <Form onSubmit={submitForm} className='col-12 m-auto my-2' style={{maxWidth: '100%'}}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <div className='row'>
          <div className='col-6'>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>First Name <span style={{color:"red",fontSize:"14px"}}>*</span></Form.Label>
              <Form.Control required type="text"
                name="first_name"
                value={details?.first_name}
                onChange={handleChange}
                onBlur={handleBlank}
                placeholder="Enter First Name"
                style={{borderRadius: '0'}} 
                className={isBlank ? '' : 'is-invalid'}/>
                {!isBlank && <div className="invalid-feedback">First name is required</div>}
            </Form.Group>
          </div>
          <div className='col-6'>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Last Name <span style={{color:'red',fontSize:'14px'}}>*</span></Form.Label>
            <Form.Control required type="text"
              name="last_name"
              value={details?.last_name}
              onChange={handleChange}
              onBlur={handleBlank1}
              placeholder="Enter Last Name"
              style={{borderRadius: '0'}} 
              className={isBlank1 ? '' : 'is-invalid'}/>
              {!isBlank1 && <div className="invalid-feedback">Last name is required</div>}
          </Form.Group>          
          </div>
          </div>
          <Form.Label>Email address <span style={{color:'red',fontSize:'14px'}}>*</span></Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            value={details?.email}
            onChange={handleChange}
            onBlur={handleEmailBlur} // Trigger email validation on blur
            placeholder="Enter Email"
            style={{borderRadius: '0'}}
            className={isEmailValid ? '' : 'is-invalid'}
          />
          {!isEmailValid && <div className="invalid-feedback">
              {details?.email!='' ? "Invalid email format":"Email is required"}
            </div>}
        </Form.Group>
        <div className=' pb-5'>
          <Form.Label>Add Scripts</Form.Label>
          {/* <Multiselect
            options={options}
            name="particulars"
            onSelect={onSelectOptions}
            onRemove={onRemoveOptions}
            displayValue="label"
            closeIcon="cancel"
            placeholder="Select Options"
            selectedValues={selectedOptions}
            onKeyPressFn={checkKeyPress}
            // className="multiSelectContainer"
          /> */}

              <Select options={options}
                  name="particulars"
                  // onChange={onSelectOptions}
                  value={selectedOptions}
                  isMulti
                  placeholder="Select Options"
                  className="multiSelectContainer"
                  onChange={(e)=>{
                     setSelectedOptions(e)
                    //  setisMenuOpen(true);
                  }}
                  closeMenuOnSelect={false}
                  // menuIsOpen={isMenuOpen}
                  // onSelectOptions={onSelectOptions}
               />
        </div>
    <div className="d-flex justify-content-end">
  <Button disabled={loading2.loading_status} style={{ width: "150px", marginRight: "10px" }} variant="secondary"onClick={handleCancel}>
    Cancel
  </Button>
  <Button disabled={loading2.loading_status} style={{ width: "150px" }} variant="primary" type="submit">
    Submit
  </Button>
</div>


      </Form>
    </>
  );

}

export default SubAdminForm;
