import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { CallPOSTAPI } from '../../../helpers/API';
import { GetScripts, getUsers } from '../../../helpers/BaseFn';
import { Link, useNavigate } from 'react-router-dom';
import { BackIcon } from '../../../helpers/Icons';
import HeaderSubAdmin from '../SubAdminHeader';

const AddAgent = () => {
   const [loading2, setLoading2] = React.useState({
      loading_status: false,
      msg: '',
   });
   const user = getUsers(localStorage.getItem("callflow_token"))
   console.log(user)
   const navigate = useNavigate()
   const [details, setDetails] = useState({
      first_name: "",
      email: "",
      last_name: "",
      subAdminId:user?.user_id
   })
 

   // const handleChange = (e) => {
   //    setDetails({
   //       ...details, [e.target.name]: e.target.value
         
   //    })
   //    const { name, value } = e.target;
   //    if ((name === "first_name" || name === "last_name") && (/^[A-Za-z]+$/.test(value) || value === '')) {
   //       setDetails((prevDetails) => ({
   //         ...prevDetails,
   //         [name]: value,
   //       }));
   //     }
       
   //     if (name === "email") {
   //       setDetails((prevDetails) => ({
   //         ...prevDetails,
   //         [name]: value,
   //       }));
   //     }
   // }

   const handleChange = (e) => {
      const { name, value } = e.target;
  
      console.log(`Handling change for ${name}: ${value}`);
  
      // Check if the input value contains only letters and spaces for first_name and last_name
      if ((name === "first_name" || name === "last_name") && (/^[A-Za-z]+( [A-Za-z]*)*$/.test(value) || value === '')) {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
      
      if (name === "email") {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
    };

   const submitForm = async (e) => {
      e.preventDefault();
      
      const data = {
         first_name: details.first_name,
         last_name: details.last_name,
         email: details.email,
         subAdminId:user?.user_id
      }
      console.log(data)

      setLoading2((prevState) => ({ ...prevState, loading_status: true }));
      let res = await CallPOSTAPI('api_auth/agents', data);
    
      setLoading2((prevState) => ({ ...prevState, loading_status: false }));
      console.log(res?.data?.status)
      if (res?.data?.status) {
         setDetails({
            first_name: "",
            email: "",
            last_name: "",
         })
         toast.success("Agent Added Successfully");
         //   console.log(res?.data?.data?._id);    
         //   let id = res?.data?.data?._id;
         //   navigate('/create-verbiage/'+id);
      } else
       {
         console.log(res?.data?.status)
         toast.error("Email already exists!");
      }

   }

   return (
      <>
         <HeaderSubAdmin />
         <div className="top-heading bg-light-gray py-4">
            <div className="container d-flex justify-content-between align-items-center">
               <h4 className='text-gray-heading text-capitalize'>
                  <button className='btn text-primary' type="button"
                     onClick={() => navigate(-1)}
                  ><BackIcon /></button>
                  Add Agent
               </h4>
               <div>
                  <Link className='btn text-primary mx-2' to={"/sub-admin/agent/list"} >View Agent</Link>
               </div>
            </div>
         </div>
         <Form onSubmit={submitForm} className='col-8 m-auto my-5' style={{maxWidth: '40%'}}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className='row'>
            <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>First Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="first_name"
                     value={details?.first_name}
                     onChange={handleChange}
                     placeholder="Enter First Name"
                     style={{borderRadius: '0'}} />
               </Form.Group>
               </div>
               <div className='col-6'>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Last Name <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
                  <Form.Control required type="text"
                     name="last_name"
                     value={details?.last_name}
                     onChange={handleChange}
                     placeholder="Enter Last Name"
                     style={{borderRadius: '0'}} />
               </Form.Group>
               </div>
               </div>
               <Form.Label>Email address <span style={{ color: 'red',fontSize: '14px'  }}>*</span> </Form.Label>
               <Form.Control required type="email"
                  name="email"
                  value={details?.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  style={{borderRadius: '0'}} />
            </Form.Group>
            <div className="d-flex justify-content-end">
               <Button disabled={loading2.loading_status} style={{ width: "150px" }} variant="primary" type="submit">
                  Submit
               </Button>
            </div>
         </Form>
      </>
   )
}

export default AddAgent