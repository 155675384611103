import React, { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

const RenderFileInput = ({ file, removeThisImage }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(file);
  }, [file]);

  const formatFileName = (fileName) => {
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    const truncatedName = nameWithoutExtension.substring(0, 4);
    const extension = fileName.split('.').pop();
    return `${truncatedName}.${extension}`;
  };

  return (
    <>
      <div className='d-flex flex-wrap gap-3 mt-4'>
 
        {fileList?.map((file, i) => {
          const formattedName = formatFileName(file.name);
          return file.type?.slice(0, 5) === 'image' ? (
            <div className='verbiage-img-upload' key={i}>
              <img src={URL.createObjectURL(file)} alt='' />
              <p className='filename'>{formattedName}</p>
              <AiFillDelete
                onClick={() => removeThisImage(i)}
                className='delete-img'
              />
            </div>
          ) : (
            <div className='verbiage-img-upload' key={i}>
              <video controls src={URL.createObjectURL(file)} alt='' />
              <p className='filename'>{formattedName}</p>
              <AiFillDelete
                onClick={() => removeThisImage(i)}
                className='delete-img-video'
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RenderFileInput;
