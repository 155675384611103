import React from 'react'
import DataGrid, { Scrolling, Pager, Paging, Column,SearchPanel } from 'devextreme-react/data-grid';
import { confirmAlert } from 'react-confirm-alert';
import { BiBlock } from 'react-icons/bi';
import { EditIcon } from '../../../helpers/Icons';
import { CgUnblock } from 'react-icons/cg'

const SubAdminTbl = ({data, setdetailsModal,setEditModal,setDetailsData,HandleUnBlock,HandleBlock}) => {
    const renderName = (e) =>{
        const item = e.data;
        return (
            <>
                <button type="button" className='btn link text-primary text-truncate-720' title={item?.user_name}
                     onClick={()=>{
                        setdetailsModal(true);
                        setDetailsData(item)
                     }}
                  >
                     {item?.first_name + " " + item?.last_name}
                  </button>
            </>
        )
    }
    const renderAction = (e)=>{
        const item = e.data;
        return (
            <div className='d-flex justify-content-right action-section' >
                  <button 
                     onClick={()=>{
                        setEditModal(true)
                        setDetailsData(item)
                     }}
                     className='action-icon btn link text-primary' title="Edit SubAdmin" >
                        <EditIcon />
                     </button>
                     {
                        item?.isBlocked ? (
                           <span style={{ width: '25px',color:'green' }} className="action-icon" type='button' onClick={() =>
                              confirmAlert({
                                 title: 'Unblock Subadmin',
                                 message: 'Are you sure to do this.',
                                 buttons: [
                                    {
                                       label: 'Yes',
                                       onClick: () => HandleUnBlock(item?._id)
                                    },
                                    {
                                       label: 'No',
                                       onClick: () => { }
                                    }
                                 ]
                              })}
                              title="Unblock Subadmin"
                           >
                              <CgUnblock size={24} />
                           </span>
                        ) : (
                           <span style={{ width: '25px' }} className="action-icon" type='button' onClick={() =>
                              confirmAlert({
                                 title: 'Block Subadmin',
                                 message: 'Are you sure to do this.',
                                 buttons: [
                                    {
                                       label: 'Yes',
                                       onClick: () => HandleBlock(item?._id)
                                    },
                                    {
                                       label: 'No',
                                       onClick: () => { }
                                    }
                                 ]
                              })}
                              title="Block Subadmin"
                           >
                              <BiBlock color="red" size={24} />
                           </span>
                        )
                     }
                  </div>
        )
    }
    const allowedPageSizes = [5, 10, 'all'];
  return (
    <>
        <DataGrid
          id='gridContainer'
          dataSource={data}
          keyExpr="_id"
          showBorders={true}
        >
            <Column dataField="name" caption="Name" cellRender={renderName} />
            <Column dataField="email" caption="Email"  />
            <Column dataField="_id" caption="Action" cellRender={renderAction} width={100} />
          <Scrolling rowRenderingMode='virtual' />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={true}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
             />
          <Paging defaultPageSize={10} />
          <SearchPanel  visible={true}/>
        </DataGrid>    

    </>
  )
}

export default SubAdminTbl