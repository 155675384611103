'use client'
import React, { useEffect, useState ,useRef } from 'react'
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { getUsers } from '../helpers/BaseFn';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CallGETAPI } from '../helpers/API';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
export default function Header() {
    const navigate = useNavigate();
    const params = useParams();
    const router = '';
    const param_script_id = router?.query?.script_id || "";
    const { asPath } = router;
    const [UserData,setUserData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [clearTimeoutId, setClearTimeoutId] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const searchRef = useRef(null);
    const dropdownRef = useRef(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    
    useEffect(()=>{
        let userInfo = getUsers(localStorage.getItem('callflow_token'));
        if(userInfo){
            setUserData(userInfo);
        }else{
            setUserData({});
            navigate('/')
        }
        
    },[navigate])

    const handleLogout = ()=>{
        localStorage.removeItem('callflow_token');
        setUserData({})
        navigate('/');
    }




    const [scriptResult,setScriptResult] = useState([]);
    const [active_script,setactive_script] = useState([]);
    const [verbiageResult,setVerbiageResult] = useState([]);
    const handleChange  =  async(e)=>{
        const searchTerm = e.target.value;
        
        if (!searchRef.current.value) {
            setScriptResult([]);
            setVerbiageResult([]);
            return '';
          }

        setSearchTerm(searchTerm);
        clearTimeout(clearTimeoutId);
        if (searchTerm === '') {
          setScriptResult([]);
          setVerbiageResult([]);
        }else{
        let result = await  CallGETAPI("script/search_api?script_title="+searchTerm);
        let scriptResult   = result?.data?.Script_result || [];
        const active_script = scriptResult.filter((item)=>item.status === '1')
        console.log(active_script)
        console.log(scriptResult)
        
        let verbiageResult = result?.data?.Verbiage_result || [];
        if(router.route==='/script-details/[script_id]'){
            verbiageResult = verbiageResult.filter((item)=>item.script_id===param_script_id)
        setVerbiageResult(verbiageResult)
        }else{
            setactive_script(active_script)

        }
        // router.pathname
        // console.log(verbiageResult)
        // setScriptResult(scriptResult)
        // setVerbiageResult(verbiageResult)
        }
        // console.log({result,scriptResult});
    }
    const mouseLeave = ()=>{
        setScriptResult([])
    }

    const handleClickOutside = (e) => {
        if (searchRef.current && !searchRef.current.contains(e.target)) {
          setSearchTerm('');
          setScriptResult([]);
          setVerbiageResult([]);
        }
      };
      const handleKeyDown = (e) => {
        console.log(searchTerm)
        if (e.key === 'Backspace' && searchTerm.length === 0) {
           
          clearTimeout(clearTimeoutId);
          const timeoutId = setTimeout(() => {
            setScriptResult([]);
            setVerbiageResult([]);
          }, 300); // Delay of 300 milliseconds before clearing the search list
          setClearTimeoutId(timeoutId);
        }
      };
      
   
 

      
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <div>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <div className="navbar-brand">
                        <Link  to="/dashboard">
                        <img
                            src={"/assets/images/logo.png"}
                            alt='logo'
                            width={180}
                            // height={50}
                        /></Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse mx-auto main-header-content" id="navbarSupportedContent" >
                        <div   className="col-md-9 form-input-div mx-lg-5 relative "  >
                            <input ref={searchRef}  
                            // onKeyDown={handleKeyDown}
                             className="form-input py-2 ps-5 text-gray-light" type="search" placeholder="Search with the title or keywords" aria-label="Search"

                                onChange={handleChange}
                                // onBlur={mouseLeave}
                            />
                            {searchRef?.current?.value != "" && ((active_script.length > 0 ) || verbiageResult.length > 0 ) ? 
                            <ul className='list list-wrapper search-list' >

                                {active_script.map((item,index)=>(
                                    <li key={index}> 
                                     - &nbsp;
                                     <Link to={`/script-details/${encodeURIComponent(item?._id)}/?script_title=${encodeURIComponent(item?.script_title)}`} className='btn'>
                                       {item?.script_title}
                                     </Link>
                                    </li>
                                ))}
                                 {verbiageResult.length > 0 ? 
                                    verbiageResult.map((item,indx)=>(
                                  <li key={indx}> 
                                    <Link to={`/script-details/${encodeURIComponent(item?.script_id)}/?verbiage_id=${encodeURIComponent(item?._id)}`} className='btn'>
                                          {item?.verbiage_title}
                                    </Link>
                                 </li>
                                    ))
                                    :" "}
                                   
                            </ul>:""}



                            <div className="search-icon">
                                <img
                                    src={"/assets/images/search.svg"}
                                    alt='search'
                                    width={20}
                                    height={20}
                                />
                            </div>
                        </div>



                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            {UserData?.user_id ? 
                            <>


                            <li>

                            <Button
                                    id="profile-dropdown"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <div className="d-flex gap-3">
                                        <Avatar>{UserData?.first_name?.[0]}</Avatar>
                                            <div className="user-details">
                                                <h5 className='text-gray-heading'>{UserData?.first_name}</h5>
                                                <p className='text-gray-light'>{UserData?.email}</p>
                                            </div>
                                        </div>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'profile-dropdown',
                                    }}
                                >
                                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </li>

                            {/* <li className="nav-item">
                                <div className="d-flex gap-3">
                                    <Avatar>{UserData?.first_name?.[0]}</Avatar>
                                    <div className="user-details">
                                        <h5 className='text-gray-heading'>{UserData?.first_name}</h5>
                                        <p className='text-gray-light'>{UserData?.email}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item" >
                                <button className='nav-link text-gray-heading' type="button" onClick={handleLogout} >Logout</button>
                            </li> */}
                            </>
                            :
                            <li className="nav-item" >
                                <Link className="nav-link text-gray-heading" href="/" >Login</Link>
                            </li>}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
